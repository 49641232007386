import { TextField } from "@mui/material";
import React, { createRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/css/business-partner/accounts-onboarding.css";
import AutoComplete from "../components/autocomplete-input";
import TextFiealdInput from "../components/textFiald";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import MainTable from "../components/main-tables";
import Dialog from "@mui/material/Dialog";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DialogContent from "@mui/material/DialogContent";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormGroup,
  InputAdornment,
  MenuItem,
  Select,
} from "@mui/material";
import * as Yup from "yup";
import Checkbox from "@mui/material/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CancelIcon from "@mui/icons-material/Cancel";
import { useFormik, Field, Form, ErrorMessage } from "formik";
import AutoCompleteMultiple from "../components/multi-drop-down";
import DownloadIcon from "@mui/icons-material/Download";
import { raise_api_call_list_general_setting } from "../../redux/general-setting/list-general-setting-reducer";
import { useNavigate } from "react-router";
import {
  account_onboarding_complete_api,
  raise_api_call_update_acounts_onboarding,
  update_acounts_onboarding_is_success,
} from "../../redux/business-partner/onboarding/update-acounts-onboarding";
import {
  clear_data_bank_Ifsc_code,
  list_bank_Ifsc_code,
} from "../../redux/sap-master/get-bank-ifsc-code";
import { list_bank_master } from "../../redux/sap-master/bank-master";
import { list_down_pay_account } from "../../redux/sap-master/downpayaccount";
import { list_wtax_master } from "../../redux/sap-master/list-wtax-master";
import { list_business_channel } from "../../redux/prefered-master/business-channel-list";
import { isNullishCoalesce } from "typescript";
import { generate_cost_center_from_api } from "../../redux/business-partner/onboarding/get-ar-andhd-locations";
import { get_consalidation_business_partner } from "../../redux/business-partner/onboarding/list-consalidation-business-partner";
import { list_customer_receivable } from "../../redux/sap-master/customer-recivable";
import { list_vendor_pay } from "../../redux/sap-master/vendor-pay";
import { list_branch_master } from "../../redux/prefered-master/list-branch-master";
import {
  request_clarification,
  request_clarification_complete_api,
  request_clarification_is_success,
} from "../../redux/business-partner/partner-master/request-clarification";
import { list_group_master } from "../../redux/sap-master/group-master";
import { list_state_master } from "../../redux/prefered-master/list-state-master";
import { configs, COUNTRIES } from "../../common";
import CompletePopup from "../components/response-pop-up";
import { raise_api_call_list_partner_one_detail } from "../../redux/business-partner/partner-master/list-one-partner-details";
import { ReactNode } from "react";
import { toast } from "react-toastify";
import { IDeleteDialog } from "../../Components/DeleteDialog/IDeleteDialog";
import DeleteDialog from "../../Components/DeleteDialog/DeleteDialog";
import Snackbar from "../../Components/Snackbar/Snackbar";
import { IAPIResponse } from "../../Components/Snackbar/ISnackbar";
//file supported formats
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "application/pdf",
];
type TabErrors = {
  [key: string]: any; // Adjust 'any' to match the structure of your tabErrors object
};

const AccountOnBoarding = () => {
  const [value, setValue] = React.useState("1");
  // console.log("value", typeof value);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Snackbar response
  const [errorRes, setErrorRes] = useState<IAPIResponse>({});
  // change tabs
  const [responseRequestClarification, setRequestClarification] =
    useState<boolean>(false);
  const [tabErrors, setTabErrors] = useState<TabErrors>({});
  // console.log("tabErrors", tabErrors);

  const [tabChange, setTabChange] = useState(false);

  const [tabValid, setTabValid] = useState(false);
  // console.log("tabValid", tabValid);

  const [responsePopup, setResponsePopup] = useState<boolean>(false);
  // const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
  //   setValue(newValue);
  //   handleSubmit();
  //   setTabErrors(formik.errors);
  // };

  const [businessChannelState, setBusinessChanelState] = useState<any>("");
  const [payment_term_code, setpayment_term_code] = useState<any>("");
  const [PriceList, setPriceList] = useState<any>("");
  const [WTAXCodeSelected, setWTAXCode] = useState<any>("");
  const [consolidationCode, setConsolidationCode] = useState<any>("");
  const [AsseseeType, setAsseseeType] = useState<any>("");
  const group_master_data = useSelector((state: any) => state.GroupMasterList);
  const [businessChannelActiveData, setbusinessChannelActiveData] =
    useState<any>(null);
  const generalSettingList = useSelector((state: any) => state.GeneralSetting);
  // console.log("generalSettingList", generalSettingList);
  const consalidationBusinessPartnerList = useSelector(
    (state: any) => state.ListConsalidationBusinessPartner
  );
  // console.log(
  //   "consalidationBusinessPartnerList",
  //   consalidationBusinessPartnerList
  // );
  const updateOnboardingDataApi = useSelector(
    (state: any) => state.UpdateAcountsOnboarding
  );
  const onboardingData = useSelector((state: any) => state.AddPartnerData);
  const onboardingValues: any =
    Object.keys(onboardingData.data).length != 0 ? onboardingData.data : false;

  // console.log("OnboardingValues>>>>>", onboardingValues);
  const find_last_array = onboardingValues.approvall.length;

  const parterner_status =
    onboardingValues.approvall[find_last_array - 1].status;
  // console.log("Partener_Status>>>>", parterner_status);

  // console.log(
  //   "Bank_Value_Check>>>>",
  //   onboardingValues
  //     ? onboardingValues.bankDetail.length != 0 &&
  //       (parterner_status === "Clarification Submitted" ||
  //         parterner_status === "Partner Onboarding Completed")
  //       ? onboardingValues.bankDetail[0].bic_ifsc_swift_code
  //       : onboardingValues.paymentTerms.length != 0
  //       ? onboardingValues.paymentTerms[0].bic_swift_code
  //         ? onboardingValues.paymentTerms[0].bic_swift_code
  //         : ""
  //       : ""
  //     : ""
  // );

  const [gst_filling_status, setGstFillingStatus] = useState<boolean>(
    onboardingValues
      ? onboardingValues.udff.length != 0
        ? !onboardingValues.udff[0].filling_status
          ? onboardingValues.businessPartner.length === 0
            ? true
            : onboardingValues.businessPartner[0].gst_filling_status
          : onboardingValues.udff[0].filling_status
        : onboardingValues
        ? onboardingValues.businessPartner.length === 0
          ? true
          : onboardingValues.businessPartner[0].gst_filling_status
        : true
      : true
  );
  // file validation

  const validationSchema = Yup.object().shape({
    employee_id: Yup.string()
      .max(8, "maximum 8 character")
      .nullable()
      .notRequired(),
    annual_turn_over: Yup.string()
      .max(10, "maximum 10 character")
      .matches(/^[0-9]+$/, "Must be only digits")
      .notRequired(),
    group_name: Yup.string().notRequired(),
    partner_type: Yup.string().required("partner type is required"),
    about: Yup.string().max(1000, "maximum 1000 characters"),
  });
  const validationSchema1 = Yup.object().shape({
    tolerance_percentage: Yup.number()
      .max(100, "maximum 25 digits")
      .notRequired()
      .nullable(),
    // Yup.number().when("partner_type", {
    //is: (value: string) => value === "V",
    // then: Yup.number()
    // max(100, "maximum percentage 100")
    //  .required("Tolerance Percentage is required")
    //  .nullable(), //  otherwise: Yup.number()
    // .max(100, "maximum 25 digits")
    // .notRequired()
    //  .nullable(),
    // }),
    // store_code: Yup.string().required("Store Code is required").nullable(),
    // store_name: Yup.string().required("Store Name is required").nullable(),
    discount_group: Yup.string().when("partner_type", {
      is: (value: string) => value === "C",
      then: Yup.string()
        // .max(100, "maximum percentage 100")
        .required("Discount is required")
        .nullable(),
      otherwise: Yup.string().notRequired().nullable(),
    }),

    gst_type: Yup.string().required("gst type is required").nullable(),

    msme: Yup.boolean().notRequired(),
    msme_no: Yup.string()
      .when("msme", {
        is: (value: boolean) => value === true,
        then: Yup.string()
          .max(25, "maximum 25 digits")
          .required("msme no is required")
          .nullable(),
        otherwise: Yup.string()
          .max(25, "maximum 25 digits")
          .notRequired()
          .nullable(),
      })
      .max(25, "maximum 25 digits"),
    group_name: Yup.string().notRequired(),
    partner_type: Yup.string().notRequired(),
    //   .required("partner type is required"),
    business_chanel: Yup.string().when("partner_type", {
      is: (value: string) => value === "C",
      then: Yup.string().required("business Chanel is required").nullable(),
      otherwise: Yup.string().notRequired().nullable(),
    }),
    state: Yup.string().when("partner_type", {
      is: (value: string) => value === "C",
      then: Yup.string().required("dimension state is required").nullable(),
      otherwise: Yup.string().notRequired().nullable(),
    }),

    // cr_location: Yup.string().required("Cr location is required"),
    // ar_location: Yup.string().required("ar location is required"),
    // hd_location: Yup.string().required("hd location is required"),
    // Yup.string().when("partner_type", {
    //  is: (value: string) => value === "C",
    //  then: Yup.string()
    // .matches(/^[0-9]+$/, "Must be only digits")
    //  .notRequired()
    //  .max(13, "maximum 13 digits")
    //  .nullable(),
    //  otherwise: Yup.string()
    //  .matches(/^[0-9]+$/, "Must be only digits")
    //  .max(13, "maximum 13 digits")
    //  .required("credit limit is required")
    //  .nullable(), // }),
  });
  const validationSchema2 = Yup.object().shape({
    telephone_no: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .max(50, " maximum 50 character")
      .notRequired()
      .nullable(),
    business_chanel: Yup.string().when("partner_type", {
      is: (value: string) => value === "C",
      then: Yup.string().required("business Chanel is required").nullable(),
      otherwise: Yup.string().notRequired().nullable(),
    }),
    state: Yup.string().when("partner_type", {
      is: (value: string) => value === "C",
      then: Yup.string().required("dimension state is required").nullable(),
      otherwise: Yup.string().notRequired().nullable(),
    }), // cr_location: Yup.string().required("Cr location is required"), // ar_location: Yup.string().required("ar location is required"), // hd_location: Yup.string().required("hd location is required"),
    mobile_phone_no: Yup.string()
      .max(50, "maximum 50 digits")
      .matches(/^[0-9]+$/, "Must be only digits")
      .required("Mobile no is required"),

    // bank_country: Yup.string().required("bank country is required").nullable(),

    email_id: Yup.string()
      .email("contact_email_id is invalid")
      .max(100)
      .required("email id is required"),
    payment_branch: Yup.string().notRequired(),
  });
  // const validationSchema3 = Yup.object().shape({
  //   contactvalidationSchema: validationSchema,
  // });
  // const validationSchema4 = Yup.object().shape({
  //   addressvalidationSchema: validationSchema,
  // });
  const validationSchema5 = Yup.object().shape({
    payment_terms: Yup.string()
      .required("payment terms is required")
      .nullable(),
    price_list: Yup.string().required("price list is required").nullable(),
    credit_limit: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .notRequired()
      .max(13, "maximum 13 digits")
      .nullable(), // Yup.string().when("partner_type", { //  is: (value: string) => value === "C", //  then: Yup.string() //  .matches(/^[0-9]+$/, "Must be only digits") // .notRequired() //  .max(13, "maximum 13 digits") //  .nullable(), //  otherwise: Yup.string() //  .matches(/^[0-9]+$/, "Must be only digits") //  .max(13, "maximum 13 digits") //  .required("credit limit is required") //  .nullable(), // }),
    commitment_limit: Yup.string().when("partner_type", {
      is: (value: string) => value === "C",
      then: Yup.string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .notRequired()
        .max(13, "maximum 13 digits")
        .nullable(),
      otherwise: Yup.string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .max(13, "maximum 13 digits")
        .notRequired()
        .nullable(),
    }),
    bank_country: Yup.string().required("bank country is required").nullable(),
    IFSC_code: Yup.string().required("IFSC code is required"),
    account_no: Yup.string().required("account no is required"),
    bank_account_name: Yup.string().required("bank acount name is required"),
    payment_branch: Yup.string().notRequired(), // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "), // consolidation_business_partner: Yup.string().required( //  "consolidation business partner is required" // ),
    partner_type: Yup.string().required("partner type is required"),
  });
  const validationSchema6 = Yup.object().shape({
    group_name: Yup.string().notRequired(),
    partner_type: Yup.string().notRequired(),
    accounts_payable: Yup.string()
      .required("accounts payable is required")
      .nullable(),
    down_payment_clearing_accounts: Yup.string()
      .required("down_payment_clearing_accounts is required")
      .nullable(),

    down_payment_interim_accounts: Yup.string()
      .required("down_payment_interim_accounts is required")
      .nullable(),
    pan_number: Yup.mixed().when(["group_name", "partner_type"], {
      is: (value: string, partner_type: string) =>
        value === "162" && partner_type === "V",
      then: Yup.mixed().notRequired().nullable(),
      otherwise: Yup.mixed()
        .required("Pan No Is Required")
        .test("panNumber", "invalid pan number", (value: any) => {
          let regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

          if (regex.test(value)) {
            return true;
          }
          return false;
        })
        .nullable(),
    }),

    subject_holding: Yup.boolean().notRequired(),
    Assesse_type: Yup.string().when("subject_holding", {
      is: (value: boolean) => value === true,
      then: Yup.string().required("assessee type is required").nullable(),
      otherwise: Yup.string().notRequired().nullable(),
    }),
    wtax_code: Yup.array().when("subject_holding", {
      is: (value: boolean) => value === true,
      then: Yup.array().min(1, "wtax code is required").nullable(),
      otherwise: Yup.array().notRequired().nullable(),
    }),
  });

  const about: string = onboardingValues ? onboardingValues.about : "";
  const cost_center_data = useSelector(
    (state: any) => state.GenerateCostCentre
  );
  const state_master_list_data = useSelector(
    (state: any) => state.StateMasterList
  );

  if (!onboardingValues) {
    // console.log(onboardingValues);
    window.location.pathname =
      localStorage.getItem("SpApperalsRole") === "account_team"
        ? "/business-partner/partner-details"
        : "/business-partner/account-team-approve";
  }
  const employee_read_only = onboardingValues
    ? onboardingValues.partner_type == "v" &&
      onboardingValues.partner_type === "162"
      ? true
      : false
    : false;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      vendor_customer_code: onboardingValues
        ? onboardingValues.vendor_customer_code
        : "",
      partner_type: onboardingValues ? onboardingValues.partner_type : "",
      employee_id: onboardingValues ? onboardingValues.employee_id : "",
      about: about,
      company_name: onboardingValues ? onboardingValues.company_name : "",

      foreign_name: onboardingValues ? onboardingValues.foreign_name : "",
      group_name: onboardingValues ? onboardingValues.group_name : "",
      currency: onboardingValues ? onboardingValues.currency : "",
      annual_turn_over: onboardingValues
        ? onboardingValues.annual_turnover
        : "",

      // udf
      tolerance_percentage: onboardingValues
        ? onboardingValues.udff.length === 0
          ? ""
          : onboardingValues.udff[0].tolerance_percentage
        : "",
      discount_group: onboardingValues
        ? onboardingValues.udff.length === 0
          ? ""
          : onboardingValues.udff[0].discount_group
        : "",
      store_code: onboardingValues
        ? onboardingValues.udff.length === 0
          ? ""
          : onboardingValues.udff[0].store_code
        : "",
      store_name: onboardingValues
        ? onboardingValues.udff.length === 0
          ? ""
          : onboardingValues.udff[0].store_name
        : "",
      gst_type: employee_read_only
        ? "UR"
        : onboardingValues
        ? onboardingValues.udff.length === 0
          ? onboardingValues.businessPartner.length === 0
            ? ""
            : onboardingValues.businessPartner[0].gst_type
          : onboardingValues.udff[0].gst_type &&
            onboardingValues.udff[0].gst_type != "undefined"
          ? onboardingValues.udff[0].gst_type
          : onboardingValues.businessPartner.length === 0
          ? ""
          : onboardingValues.businessPartner[0].gst_type
        : "",
      filling_status: onboardingValues
        ? onboardingValues.udff.length != 0
          ? !onboardingValues.udff[0].filling_status
            ? onboardingValues.businessPartner.length === 0
              ? false
              : onboardingValues.businessPartner[0].gst_filling_status
            : onboardingValues.udff[0].filling_status
          : onboardingValues
          ? onboardingValues.businessPartner.length === 0
            ? false
            : onboardingValues.businessPartner[0].gst_filling_status
          : false
        : false,
      msme_type: onboardingValues
        ? onboardingValues.udff.length != 0
          ? !onboardingValues.udff[0].msme_type
            ? onboardingValues.businessPartner.length === 0
              ? ""
              : onboardingValues.businessPartner[0].msme_type
            : onboardingValues.udff[0].msme_type
          : onboardingValues
          ? onboardingValues.businessPartner.length === 0
            ? ""
            : onboardingValues.businessPartner[0].msme_type
          : ""
        : "",
      msme: onboardingValues
        ? onboardingValues.udff.length != 0
          ? !onboardingValues.udff[0].msme
            ? onboardingValues.businessPartner.length === 0
              ? false
              : onboardingValues.businessPartner[0].msme
            : onboardingValues.udff[0].msme
          : onboardingValues
          ? onboardingValues.businessPartner.length === 0
            ? false
            : onboardingValues.businessPartner[0].msme
          : false
        : false,
      msme_no: onboardingValues
        ? onboardingValues.udff.length != 0
          ? !onboardingValues.udff[0].msme_no
            ? onboardingValues.businessPartner.length === 0
              ? ""
              : onboardingValues.businessPartner[0].msme_no
            : onboardingValues.udff[0].msme_no
          : onboardingValues
          ? onboardingValues.businessPartner.length === 0
            ? ""
            : onboardingValues.businessPartner[0].msme_no
          : ""
        : "",
      business_chanel: onboardingValues
        ? onboardingValues.udff.length === 0
          ? ""
          : onboardingValues.udff[0].business_chanel
        : "",
      state: onboardingValues
        ? onboardingValues.udff.length === 0
          ? ""
          : onboardingValues.udff[0].state
        : "",

      // cr_location: onboardingValues ? onboardingValues.udff[0].cr_location : "",
      // ar_location: onboardingValues ? onboardingValues.udff[0].ar_location : "",
      // hd_location: onboardingValues ? onboardingValues.udff[0].hd_location : "",
      // general
      telephone_no: onboardingValues
        ? onboardingValues.generals.length === 0
          ? ""
          : onboardingValues.generals[0].telephone_no
        : "",
      mobile_phone_no: onboardingValues
        ? onboardingValues.generals.length === 0
          ? onboardingValues
            ? onboardingValues.contact_phone
            : ""
          : onboardingValues.generals[0].mobile_phone_no
          ? onboardingValues.generals[0].mobile_phone_no
          : onboardingValues.contact_phone
        : "",
      email_id: onboardingValues ? onboardingValues.contact_email : "",
      type_of_business: onboardingValues
        ? onboardingValues.generals.length != 0
          ? !onboardingValues.generals[0].type_of_business
            ? onboardingValues.businessPartner.length === 0
              ? ""
              : onboardingValues.businessPartner[0].business_type
            : onboardingValues.generals[0].type_of_business
          : onboardingValues
          ? onboardingValues.businessPartner.length === 0
            ? ""
            : onboardingValues.businessPartner[0].business_type
          : ""
        : "",
      country_code: "+91",
      branch: onboardingValues
        ? onboardingValues.branch.map((a: any, index: number) => {
            return {
              name: a.branch_name,
              value: true,
              id: a.id,
            };
          })
        : [],
      contact_person: onboardingValues ? onboardingValues.contactperson : [],
      address_details: onboardingValues ? onboardingValues.contactAddress : [],
      // payment terms
      payment_terms: onboardingValues
        ? onboardingValues.paymentTerms.length === 0
          ? ""
          : onboardingValues.paymentTerms[0].payments_terms
          ? onboardingValues.paymentTerms[0].payments_terms
          : onboardingValues.payments_terms
        : "",
      bank_code: onboardingValues
        ? onboardingValues.bankDetail.length != 0 &&
          (parterner_status === "Clarification Submitted" ||
            parterner_status === "Partner Onboarding Completed")
          ? onboardingValues.bankDetail[0].bank_code
          : onboardingValues.paymentTerms.length === 0
          ? ""
          : onboardingValues.paymentTerms[0].bank_code
        : "",
      price_list: onboardingValues
        ? onboardingValues.paymentTerms.length === 0
          ? ""
          : onboardingValues.paymentTerms[0].price_list
        : "",
      credit_limit: onboardingValues
        ? onboardingValues.paymentTerms.length === 0
          ? ""
          : onboardingValues.paymentTerms[0].credit_limit
        : "",
      commitment_limit: onboardingValues
        ? onboardingValues.paymentTerms.length === 0
          ? ""
          : onboardingValues.paymentTerms[0].commitment_limit
        : "",
      bank_country: onboardingValues
        ? onboardingValues.paymentTerms.length === 0
          ? ""
          : onboardingValues.paymentTerms[0].bank_country_region
        : "",
      bank_name: onboardingValues
        ? onboardingValues.bankDetail.length != 0 &&
          (parterner_status === "Clarification Submitted" ||
            parterner_status === "Partner Onboarding Completed")
          ? onboardingValues.bankDetail[0].bank_name
          : onboardingValues.paymentTerms.length != 0
          ? onboardingValues.paymentTerms[0].bank_name
            ? onboardingValues.paymentTerms[0].bank_name
            : ""
          : ""
        : "",
      IFSC_code: onboardingValues
        ? onboardingValues.bankDetail.length != 0 &&
          (parterner_status === "Clarification Submitted" ||
            parterner_status === "Partner Onboarding Completed")
          ? onboardingValues.bankDetail[0].bic_ifsc_swift_code
          : onboardingValues.paymentTerms.length != 0
          ? onboardingValues.paymentTerms[0].bic_swift_code
            ? onboardingValues.paymentTerms[0].bic_swift_code
            : ""
          : ""
        : "",
      account_no: onboardingValues
        ? onboardingValues.bankDetail.length != 0 &&
          (parterner_status === "Clarification Submitted" ||
            parterner_status === "Partner Onboarding Completed")
          ? onboardingValues.bankDetail[0].account_no
          : onboardingValues.paymentTerms.length != 0
          ? onboardingValues.paymentTerms[0].account_no
            ? onboardingValues.paymentTerms[0].account_no
            : ""
          : ""
        : "",
      bank_account_name: onboardingValues
        ? onboardingValues.bankDetail.length != 0 &&
          (parterner_status === "Clarification Submitted" ||
            parterner_status === "Partner Onboarding Completed")
          ? onboardingValues.bankDetail[0].bank_account_name
          : onboardingValues.paymentTerms.length != 0
          ? onboardingValues.paymentTerms[0].bank_account_name
            ? onboardingValues.paymentTerms[0].bank_account_name
            : ""
          : ""
        : "",
      payment_branch: onboardingValues
        ? onboardingValues.bankDetail.length != 0 &&
          (parterner_status === "Clarification Submitted" ||
            parterner_status === "Partner Onboarding Completed")
          ? onboardingValues.bankDetail[0].branch
          : onboardingValues.paymentTerms.length != 0
          ? onboardingValues.paymentTerms[0].branch
            ? onboardingValues.paymentTerms[0].branch
            : ""
          : ""
        : "",
      subject_holding: onboardingValues
        ? onboardingValues.accounts.length === 0
          ? false
          : onboardingValues.accounts[0].subject_holding
          ? true
          : false
        : false,

      consolidation_business_partner: onboardingValues
        ? onboardingValues.accounts.length === 0
          ? ""
          : onboardingValues.accounts[0].consolidation_business_partner
        : "",
      accounts_payable: onboardingValues
        ? onboardingValues.accounts.length === 0
          ? ""
          : onboardingValues.accounts[0].accounts_payable
        : "",
      down_payment_clearing_accounts: onboardingValues
        ? onboardingValues.accounts.length === 0
          ? ""
          : onboardingValues.accounts[0].down_payment_clearing_accounts
        : "",
      down_payment_interim_accounts: onboardingValues
        ? onboardingValues.accounts.length === 0
          ? ""
          : onboardingValues.accounts[0].down_payment_interim_accounts
        : "",
      pan_number: onboardingValues
        ? onboardingValues.accounts.length != 0
          ? onboardingValues.accounts[0].pan_number
            ? onboardingValues.accounts[0].pan_number
            : onboardingValues.businessPartner.length === 0
            ? ""
            : onboardingValues.businessPartner[0].pan_no
          : onboardingValues
          ? onboardingValues.businessPartner.length === 0
            ? ""
            : onboardingValues.businessPartner[0].pan_no
          : ""
        : "",
      Assesse_type: onboardingValues
        ? onboardingValues.accounts.length != 0
          ? onboardingValues.accounts[0].Assesse_type
          : ""
        : "",
      wtax_code: onboardingValues
        ? onboardingValues.accounts.length === 0
          ? []
          : onboardingValues.accounts[0].wtax_code.length != 0
          ? onboardingValues.accounts[0].wtax_code.map((e: any) => {
              const data = { id: e.id, label: e.WTName, value: e.WTCode };
              return data;
            })
          : []
        : [],
    },
    validationSchema:
      value == "1"
        ? validationSchema1
        : value == "2"
        ? validationSchema2
        : // : value == "3"
        // ? validationSchema3
        // : value == "4"
        // ? validationSchema4
        value == "5"
        ? validationSchema5
        : value == "6"
        ? validationSchema6
        : "",

    onSubmit: (a: any) => {
      if (tabChange) {
        submit(a);
      }
      setTabChange(false);

      // console.log("submit", submit);
    },
  });
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
  } = formik;
  console.log("Formik Values", values);
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    console.log(newValue);

    handleSubmit();
    setTabChange(false);

    formik.validateForm().then((errors) => {
      formik.setErrors(errors);

      setTabErrors(errors);

      if (Object.keys(errors).length === 0) {
        // console.log("value123", value);
        if (newValue != "7" && newValue != "6") {
          setValue(newValue);
        }
        if (newValue >= "5") {
          if (values.address_details.length === 0) {
            // alert("Please fill address details");
            setErrorRes({
              status_code: 401,
              message: "Please create at least one address to proceed.",
              status: false,
            });
            setValue("4");
            return;
          }
          const find_default = values.address_details.filter(
            (address: any) => address.default === true
          );
          console.log("find default", find_default);

          if (find_default.length === 0) {
            // alert("At least one address must be default.");
            setErrorRes({
              status_code: 401,
              message: "Please ensure at least one address is set as default.",
              status: false,
            });
            setValue("4");
            return;
          }

          // console.log("Address Next");
        }
        if (newValue === "6") {
          if (
            formik.values.price_list &&
            formik.values.payment_terms &&
            formik.values.bank_country != null
          ) {
            setValue(newValue);
          }
        }
        if (newValue === "7") {
          if (
            formik.values.price_list &&
            formik.values.payment_terms &&
            formik.values.bank_country != null &&
            formik.values.down_payment_clearing_accounts &&
            formik.values.accounts_payable &&
            formik.values.down_payment_interim_accounts != null
          ) {
            setValue(newValue);
            setTabValid(true);
          }
        }
      } else {
        // console.log("Cannot change tab due to form errors:", errors);
      }
    });
    // console.log(formik.values.price_list);
  };
  useEffect(() => {
    // Set tabErrors to errors
    setTabErrors(errors);
  }, [errors]);

  const bank_master_data = useSelector((state: any) => state.BankMasterListApi);
  const vendor_pay_data = useSelector((state: any) => state.VendorPayListApi);
  const branch_master_data = useSelector(
    (state: any) => state.ListBranchMaster
  );
  const [requestForClarification, setRequestForClarification] =
    React.useState(false);
  const openRequestClarification = () => {
    setRequestForClarification(true);
  };
  const CloseRequestClarification = () => {
    setRequestForClarification(false);
  };
  const customer_receivable_data = useSelector(
    (state: any) => state.CustomerReceivable
  );
  const wtax_master_data = useSelector((state: any) => state.WTAXMasterListApi);
  const business_channel_data = useSelector(
    (state: any) => state.BusinessChannelList
  );
  const down_pay_account_data = useSelector(
    (state: any) => state.DownPayAccount
  );

  const reuest_clarification_api = useSelector(
    (state: any) => state.RequestClarification
  );
  // useEffect
  useEffect(() => {
    dispatch(list_bank_master(""));
    dispatch(list_state_master(""));
    dispatch(list_business_channel(""));
    dispatch(list_group_master(""));
    dispatch(list_wtax_master(""));
    dispatch(list_customer_receivable(""));
    dispatch(list_vendor_pay(""));
    dispatch(list_branch_master(""));
    dispatch(list_down_pay_account(""));
    dispatch(raise_api_call_list_general_setting(""));
    dispatch(
      get_consalidation_business_partner({
        partnerMasterId: onboardingValues ? onboardingValues.id : "",
      })
    );
    if (updateOnboardingDataApi.api_completed) {
      // console.log(updateOnboardingDataApi);
      setResponsePopup(true);
      dispatch(account_onboarding_complete_api(false));
    }
    if (reuest_clarification_api.api_completed) {
      setRequestClarification(true);
      dispatch(request_clarification_complete_api(false));
      setResponsePopup(true);
    }

    if (reuest_clarification_api.isSuccess) {
      CloseRequestClarification();
      dispatch(request_clarification_is_success(""));
    }
  }, [updateOnboardingDataApi, reuest_clarification_api]);

  // submit
  const submit = (a: any) => {
    const formdata = new FormData();
    const contactDetail: any = [];
    a.contact_person.forEach((e: any) => {
      const data = {
        default: e.default,
        id: e.id,
        phone: e.phone,
        title: e.title,
        email: e.email,
        country_code: e.country_code,
        contact_person: e.contact_person,
      };
      contactDetail.push(data);
    });
    const addressFormData = new FormData();
    // const addressDetail = AddressInfoData.data;
    a.address_details.forEach((a: any, index: any) => {
      formdata.append("gst_certificate", a.gst_certificate);
      const data: any = {
        address_type: a.address_type,
        address_title: a.address_title,
        address_name3: a.address_name3,
        block: a.block,
        address_name2: a.address_name2,
        street_pobox: a.street_pobox,
        gst_type: a.gst_type,
        city: a.city,
        zip_code: a.zip_code,
        country: a.country,
        gst_certificate: a.gst_certificate,
        state: a.state,
        gstin: a.gstin,
        default: a.default,
      };

      formdata.append("address[]", JSON.stringify(data));
    });

    const data: any = {
      partnerMasterId: onboardingValues ? onboardingValues.id : null,
      partner_type: a.partner_type,
      vendor_customer_code: a.vendor_customer_code,
      employee_Id: a.employee_Id,
      company_name: a.company_name,
      group_name: a.group_name,
      foreign_name: a.foreign_name,
      currency: a.currency,
      annual_turn_over: a.annual_turn_over,
      about: a.about,
      udf: {
        tolerance_percentage: a.tolerance_percentage,
        discount_group: a.discount_group,
        store_code: a.store_code,
        store_name: a.store_name,
        gst_type: a.gst_type,
        filling_status: a.filling_status,
        msme: a.msme,
        msme_type: a.msme_type,
        msme_no: a.msme_no,
        business_chanel: a.business_chanel,
        state: a.state,
      },
      general: {
        telephone_no: a.telephone_no,
        mobile_phone_no: a.mobile_phone_no,
        email_Id: a.email_id,
        type_of_business: a.type_of_business,
        branch: a.branch.filter((a: any) => {
          return a.value === true;
        }),
        status: a.status,
      },
      branch: a.branch.filter((a: any) => {
        return a.value === true;
      }),
      person: contactDetail,
      // address: addressFormData,
      payments_terms: {
        payments_terms: a.payment_terms,
        price_list: a.price_list,
        credit_limit: a.credit_limit,
        commitment_limit: a.commitment_limit,
        bank_country_region: a.bank_country,
        bank_name: a.bank_name,
        bank_code: a.bank_code,
        account_no: a.account_no,
        bic_swift_code: a.IFSC_code,
        bank_account_name: a.bank_account_name,
        branch: a.payment_branch,
      },
      account: {
        consolidation_business_partner: a.consolidation_business_partner,
        accounts_payable: a.accounts_payable,
        down_payment_interim_accounts: a.down_payment_interim_accounts,
        pan_number: a.pan_number,
        Assesse_type: a.Assesse_type,
        subject_holding: a.subject_holding,
        wtax_code: a.wtax_code,
        down_payment_clearing_accounts: a.down_payment_clearing_accounts,
      },
    };

    // console.log(data);
    formdata.append("values", JSON.stringify(data));
    // for (const value of addressFormData.values()) {
    //   console.log(value);
    // }
    // console.log(">>>>>Form Data", formdata);

    dispatch(raise_api_call_update_acounts_onboarding(formdata));
  };

  const currency_option =
    generalSettingList.data.length != 0
      ? generalSettingList.data.status
        ? generalSettingList.data.result
            .filter((e: any) => {
              if (e.page === "Currency") {
                return e;
              }
            })
            .map((e: any) => {
              const data = { label: e.description, value: e.name };
              return data;
            })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];

  // get contact person
  const contact_person_detail = (a: any) => {
    setFieldValue("contact_person", a);
  };
  const getAddress_details = (a: any) => {
    setFieldValue("address_details", a);
  };

  console.log("Address details", getAddress_details);

  const partner_type_option =
    generalSettingList.data.length != 0
      ? generalSettingList.data.status
        ? generalSettingList.data.result
            .filter((e: any) => {
              if (e.page === "partner_type") {
                return e;
              }
            })
            .map((e: any) => {
              const data = { label: e.description, value: e.name };
              return data;
            })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];

  const group_name_option =
    group_master_data.data.length != 0
      ? group_master_data.data.status
        ? group_master_data.data.result
            .filter((e: any) => {
              if (values.partner_type === "V" && "S" === e.group_type) {
                return e;
              }
              if (values.partner_type === e.group_type) {
                return e;
              }
            })
            .map((e: any) => {
              const data = { label: e.group_name, value: e.group_code };
              return data;
            })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];
  return (
    <>
      {/* Address default and length check and error msg snackbar */}
      <Snackbar response={errorRes} />
      <div className="account-onboarding">
        {responsePopup ? (
          <CompletePopup
            data={
              responseRequestClarification
                ? reuest_clarification_api
                : updateOnboardingDataApi
            }
            responsePopup={responsePopup}
            setCompletePopup={(e: any) => {
              if (updateOnboardingDataApi.isSuccess) {
                dispatch(update_acounts_onboarding_is_success(""));
                dispatch(
                  raise_api_call_list_partner_one_detail(
                    "?id=" + onboardingValues ? onboardingValues.id : ""
                  )
                );
                navigate(
                  localStorage.getItem("SpApperalsRole") === "account_team"
                    ? "/business-partner/partner-details"
                    : "/business-partner/account-team-approve"
                );
              }
              setResponsePopup(false);
              setRequestClarification(false);
            }}
          />
        ) : (
          ""
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={
            generalSettingList.isLoading ||
            bank_master_data.isLoading ||
            vendor_pay_data.isLoading ||
            customer_receivable_data.isLoading ||
            wtax_master_data.isLoading ||
            business_channel_data.isLoading ||
            down_pay_account_data.isLoading ||
            updateOnboardingDataApi.isLoading ||
            reuest_clarification_api.isLoading
              ? true
              : false
          }
        >
          {/* <CircularProgress color="inherit" /> */}
          <img
            style={{
              height: "150px",
            }}
            src={require("../../assets/images/3.gif")}
          ></img>
        </Backdrop>
        <div className=" m-3 mx-5 d-flex account-onboarding-head">
          <div className="title">
            <h5>Accounts Onboarding</h5>
          </div>
          <div
            className="ms-auto clarification-button"
            onClick={() => {
              openRequestClarification();
            }}
          >
            Request For Clarification
          </div>
        </div>
        <div className="forms m-4 mx-5">
          <div className="row">
            <div className="col-4">
              <TextFiealdInput
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                notRequire={true}
                name="vendor_customer_code"
                readOnly={true}
                touched={touched}
                setGstFillingStatus
                errors={errors}
                label="Vendor / Customer Code"
              />
            </div>

            <div className="col-4 ">
              <AutoComplete
                pageOption={partner_type_option}
                name="partner_type"
                touched={touched}
                handleBlur={handleBlur}
                errors={errors}
                values={values}
                label="Partner Type"
                vari={(e: any) => {
                  setFieldValue("partner_type", e ? e.value : "");
                  setFieldValue("group_name", "");
                }}
              />
            </div>
            <div className="col-4 ">
              <AutoComplete
                pageOption={group_name_option}
                name="group_name"
                touched={touched}
                handleBlur={handleBlur}
                errors={errors}
                values={values}
                label="Group Name"
                vari={(e: any) => {
                  if (
                    values.partner_type === "V" &&
                    (e ? e.value : "") === "162"
                  ) {
                    setFieldValue("gst_type", "UR");
                    setFieldValue("msme", false);
                    setFieldValue("filling_status", false);
                    setFieldValue("subject_holding", false);
                  }
                  setFieldValue("group_name", e ? e.value : "");
                }}
              />
            </div>
            {values.partner_type == "V" && values.group_name == "162" ? (
              <div className="col-4 mt-4">
                <TextFiealdInput
                  notRequire={true}
                  name="employee_id"
                  label="Employee ID"
                  touched={touched}
                  errors={errors}
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>
            ) : (
              ""
            )}
            <div className="col-4 mt-4">
              <TextFiealdInput
                name="company_name"
                label="Company Name"
                touched={touched}
                errors={errors}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </div>
            <div className="col-4 mt-4">
              <TextFiealdInput
                name="foreign_name"
                label="Foreign Name"
                touched={touched}
                errors={errors}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </div>

            <div className="col-4 mt-4">
              <AutoComplete
                pageOption={currency_option}
                name="currency"
                touched={touched}
                handleBlur={handleBlur}
                errors={errors}
                values={values}
                label="Currency"
                vari={(e: any) => {
                  setFieldValue("currency", e ? e.value : "");
                }}
              />
            </div>
            <div className="col-4 mt-4">
              <TextFiealdInput
                name="annual_turn_over"
                label="Annual Turn Over"
                touched={touched}
                errors={errors}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </div>
            <div className="col-12 mt-4">
              <div
                className=" px-3 input-field  d-flex flex-column"
                id="abouts"
              >
                <label className="">
                  About <span>*</span>
                </label>
                <TextField
                  className="w-100"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      borderRadius: "0px",
                      height: "100px",
                      textAlign: "left",
                    },

                    fontWeight: "700",
                    fontFamily: "Roboto",
                  }}
                  multiline
                  id="about"
                  inputProps={{
                    style: {
                      height: "80px",
                    },
                  }}
                  name="about"
                  error={errors.about && touched.about ? true : false}
                  value={values.about}
                  helperText={
                    touched.about && errors.about
                      ? (errors.about as ReactNode)
                      : undefined
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="tabs mt-5 mb-5">
          <div className="mx-5">
            <Box sx={{ width: "100%" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleTabChange}
                    className="w-100"
                    aria-label="lab API tabs example"
                  >
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        fontWeight: 700,
                        width: "14%",
                      }}
                      label="UDF"
                      value="1"
                    />
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        width: "14%",
                        fontWeight: 700,
                      }}
                      label="General"
                      value="2"
                    />
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        width: "14%",
                        fontWeight: 700,
                      }}
                      label="Contact Person"
                      value="3"
                    />
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        width: "14%",
                        fontWeight: 700,
                      }}
                      label="Address"
                      value="4"
                    />
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        width: "14%",
                        fontWeight: 700,
                      }}
                      label="Payment Terms"
                      value="5"
                    />
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        width: "14%",
                        fontWeight: 700,
                      }}
                      label="Accounts"
                      value="6"
                    />
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        width: "14%",
                        fontWeight: 700,
                      }}
                      label="Attachments"
                      value="7"
                    />
                  </TabList>
                </Box>
                <TabPanel
                  className="p-0 pt-4 "
                  style={{
                    height: "600px",
                  }}
                  value="1"
                >
                  <UDF
                    generalSettingList={generalSettingList}
                    state_master_list_data={state_master_list_data}
                    business_channel_data={business_channel_data}
                    setBusinessChanelState={setBusinessChanelState}
                    setGstFillingStatus={setGstFillingStatus}
                    onboardingValues={onboardingValues}
                    touched={touched}
                    errors={errors}
                    values={values}
                    tabErrors={tabErrors}
                    businessChannelActiveData={businessChannelActiveData}
                    setbusinessChannelActiveData={setbusinessChannelActiveData}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                  <div className="d-flex justify-content-end mt-2">
                    <span
                      className="px-2"
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span>{" "}
                    Complete General Tabs
                  </div>
                </TabPanel>
                <TabPanel
                  className="p-0 pt-4"
                  style={{
                    height: "600px",
                  }}
                  value="2"
                >
                  <General
                    generalSettingList={generalSettingList}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    branch_master_data={branch_master_data}
                    values={values}
                    tabErrors={tabErrors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                  />
                  <div className="d-flex justify-content-end mt-2">
                    <span
                      className="px-2"
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span>{" "}
                    Complete Contact Info Tabs
                  </div>
                </TabPanel>
                <TabPanel
                  className="p-0 pt-4"
                  style={{
                    height: "600px",
                  }}
                  value="3"
                >
                  <ContactInfo
                    generalSettingList={generalSettingList}
                    contact_person_details={contact_person_detail}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    values={values}
                    tabErrors={tabErrors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                  />
                  <div className="d-flex justify-content-end mt-5">
                    <span
                      className="px-2"
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span>{" "}
                    Complete Address Details Tabs
                  </div>
                </TabPanel>
                <TabPanel
                  className="p-0 pt-4"
                  style={{
                    height: "600px",
                  }}
                  value="4"
                >
                  <AddressDetail
                    style={{
                      height: "600px",
                    }}
                    generalSettingList={generalSettingList}
                    getAddress_details={getAddress_details}
                    gst_filling_status={gst_filling_status}
                    touched={touched}
                    errors={errors}
                    tabErrors={tabErrors}
                    setFieldValue={setFieldValue}
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                  />
                  <div className="d-flex justify-content-end mt-5">
                    <span
                      className="px-2"
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span>{" "}
                    Complete Payment Terms Tabs
                  </div>
                </TabPanel>
                <TabPanel
                  className="p-0 pt-4"
                  style={{
                    height: "600px",
                  }}
                  value="5"
                >
                  <PaymentTerms
                    style={{
                      height: "600px",
                    }}
                    generalSettingList={generalSettingList}
                    bank_master_data={bank_master_data}
                    setpayment_term_code={setpayment_term_code}
                    setPriceList={setPriceList}
                    touched={touched}
                    errors={errors}
                    tabErrors={tabErrors}
                    setFieldValue={setFieldValue}
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                  />
                  <div className="d-flex justify-content-end mt-2">
                    <span
                      className="px-2"
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span>{" "}
                    Fill Accounts Tabs
                  </div>
                </TabPanel>
                <TabPanel
                  className="p-0 pt-4"
                  style={{
                    height: "600px",
                  }}
                  value="6"
                >
                  <Accounts
                    generalSettingList={generalSettingList}
                    consalidationBusinessPartnerList={
                      consalidationBusinessPartnerList
                    }
                    setConsolidationCode={setConsolidationCode}
                    onboardingValues={onboardingValues}
                    vendor_pay_data={vendor_pay_data}
                    customer_receivable_data={customer_receivable_data}
                    down_pay_account_data={down_pay_account_data}
                    wtax_master_data={wtax_master_data}
                    touched={touched}
                    tabErrors={tabErrors}
                    setWTAXCode={setWTAXCode}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    values={values}
                    AsseseeType={AsseseeType}
                    setAsseseeType={setAsseseeType}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                  />
                </TabPanel>
                <TabPanel
                  className="p-0 pt-4"
                  style={{
                    height: "600px",
                  }}
                  value="7"
                >
                  <Attachments
                    generalSettingList={generalSettingList}
                    onboardingValues={onboardingValues}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    values={values}
                    tabErrors={tabErrors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                  />
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </div>
        <div className="mt-auto general-setting-button">
          <div className="botom-navigation bg-white d-flex justify-content-end align-items-center ">
            <div
              className="next-and-back"
              style={{
                padding: "0",
                margin: "0",
              }}
            >
              <div
                onClick={() => {
                  navigate(
                    localStorage.getItem("SpApperalsRole") === "account_team"
                      ? "/business-partner/partner-details"
                      : "/business-partner/account-team-approve"
                  );
                }}
                style={{
                  width: "180px",
                  margin: "0px",
                }}
                className="back"
              >
                Cancel
              </div>
            </div>
            <div className="next-and-back">
              <div
                onClick={() => {
                  if (
                    (tabValid && Object.keys(errors).length === 0) ||
                    value === "7"
                  ) {
                    setTabValid(true);
                    handleSubmit();
                    setTabChange(true);
                  }
                }}
                className="next"
                style={{
                  width: "250px",
                  backgroundColor:
                    (tabValid && Object.keys(errors).length === 0) ||
                    value === "7"
                      ? "#46A4E3"
                      : "#f2f2f2",
                  color:
                    (tabValid && Object.keys(errors).length === 0) ||
                    value === "7"
                      ? "white"
                      : "black",
                  border:
                    (tabValid && Object.keys(errors).length === 0) ||
                    value === "7"
                      ? "none"
                      : "2px solid #c4c4c4",
                }}
              >
                Send For Approval
              </div>
            </div>
          </div>
        </div>
      </div>
      <RequestClarification
        CloseRequestClarification={CloseRequestClarification}
        onboardingValues={onboardingValues}
        requestForClarification={requestForClarification}
      />
    </>
  );
};
export default AccountOnBoarding;

// UDF
const UDF = (props: any) => {
  const dispatch = useDispatch();
  const { businessChannelActiveData, setbusinessChannelActiveData } = props;

  const {
    touched,
    errors,
    handleBlur,
    handleChange,
    values,
    setBusinessChanelState,
    setFieldTouched,
    state_master_list_data,
    setFieldValue,
  } = props;
  // console.log("values >>", setBusinessChanelState);
  const cost_center_data = useSelector(
    (state: any) => state.GenerateCostCentre
  );
  // msme type
  const MSMEOption = props.generalSettingList.data.status
    ? props.generalSettingList.data.result
        .filter((e: any) => {
          if (e.page === "msme_type") {
            return e;
          }
        })
        .map((e: any) => {
          const data = { label: e.description, value: e.name };
          return data;
        })
    : [{ label: "No data", value: "no data" }];
  const BusinessChannelOption = props.business_channel_data.data.status
    ? props.business_channel_data.data.result.map((e: any) => {
        const data = { id: e.id, label: e.name, value: e.id };
        return data;
      })
    : [{ id: 0, label: "No data", value: "no data" }];
  const BusinessChannelStateOption = props.state_master_list_data.data.status
    ? props.state_master_list_data.data.result.map((e: any) => {
        const data = { id: e.id, label: e.state_name, value: e.state_code };
        return data;
      })
    : [{ id: 0, label: "No data", value: "no data" }];
  // gst filling status
  const GstFillingStatusOption = props.generalSettingList.data.status
    ? props.generalSettingList.data.result
        .filter((e: any) => {
          if (e.page === "filling status") {
            return e;
          }
        })
        .map((e: any) => {
          const data = { label: e.description, value: e.name };
          return data;
        })
    : [{ label: "No data", value: "no data" }];
  const GstTypeOption = props.generalSettingList.data.status
    ? props.generalSettingList.data.result
        .filter((e: any) => {
          if (e.page === "filling status") {
            return e;
          }
        })
        .map((e: any) => {
          const data = { label: e.description, value: e.name };
          return data;
        })
    : [{ label: "No data", value: "no data" }];
  const state_option =
    props.generalSettingList.data.length != 0
      ? props.generalSettingList.data.status
        ? props.generalSettingList.data.result
            .filter((e: any) => {
              if (e.page === "state") {
                return e;
              }
            })
            .map((e: any) => {
              const data = { label: e.description, value: e.name };
              return data;
            })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];
  // console.log(values);
  return (
    <>
      <div className="mt-3  udf ">
        <div className="udf-form">
          <div className="row">
            <div className="col-4 mt-4">
              <TextFiealdInput
                name="tolerance_percentage"
                label="Tolerance Percentage"
                touched={touched}
                errors={errors}
                notRequire={true}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </div>
            <div className="col-4 mt-4">
              <TextFiealdInput
                name="discount_group"
                label="Discount Group"
                notRequire={values.partner_type === "C" ? false : true}
                touched={touched}
                errors={errors}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </div>
            <div className="col-4 mt-4">
              <TextFiealdInput
                name="store_code"
                label="Store Code"
                notRequire={true}
                touched={touched}
                errors={errors}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </div>
            <div className="col-4 mt-4">
              <TextFiealdInput
                name="store_name"
                label="Store Name"
                notRequire={true}
                touched={touched}
                errors={errors}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </div>

            <div className="col-4 mt-4">
              <AutoComplete
                pageOption={GstTypeOption}
                touched={touched}
                errors={errors}
                values={values}
                handleBlur={handleBlur}
                readOnly={
                  values.partner_type === "V" && values.group_name === "162"
                    ? true
                    : false
                }
                name="gst_type"
                label="GST Type"
                vari={(e: any) => {
                  if (e.value !== "RG") {
                    setFieldValue("filling_status", false);
                    props.setGstFillingStatus(false);
                  }
                  setFieldValue("gst_type", e ? e.value : "");
                }}
              />
            </div>
            {/* <div className="col-4 mt-4">
              <AutoComplete
                pageOption={GstFillingStatusOption}
                touched={touched}
                errors={errors}
                values={values}
                handleBlur={handleBlur}
                name="filling_status"
                label="Filling Status"
                vari={(e: any) => {
                  setFieldValue("filling_status", e ? e.value : "");
                }}
              />
            </div> */}
            <div className="col-4 mt-4 d-flex align-items-center">
              <FormControlLabel
                label={"Filling Status"}
                sx={{
                  fontSize: "0.8vw",
                  color: "",
                }}
                value={values.filling_status}
                onChange={(e: any) => {
                  if (values.gst_type == "RG") {
                    setFieldValue("filling_status", e.target.checked);
                    props.setGstFillingStatus(e.target.checked);
                  }
                }}
                className="ms-3"
                control={
                  <Checkbox
                    name="filling_status"
                    readOnly={values.gst_type == "RG" ? false : true}
                    checked={values.filling_status ? true : false}
                  />
                }
              />
            </div>
            <div className="col-4 mt-4 d-flex align-items-center">
              <FormControlLabel
                label={"MSME"}
                sx={{
                  fontSize: "0.8vw",
                  color: "",
                }}
                value={values.msme}
                onChange={(e: any) => {
                  if (
                    values.partner_type != "V" ||
                    values.group_name != "162"
                  ) {
                    setFieldValue("msme", e.target.checked);
                  }
                }}
                className="ms-3"
                control={
                  <Checkbox name="msme" checked={values.msme ? true : false} />
                }
              />
            </div>
            <div className="col-4 mt-4">
              <AutoComplete
                pageOption={MSMEOption}
                touched={touched}
                errors={errors}
                readOnly={!values.msme ? true : false}
                values={values}
                handleBlur={handleBlur}
                notRequire={values.msme ? false : true}
                name="msme_type"
                label="MSME Type"
                vari={(e: any) => {
                  setFieldValue("msme_type", e ? e.value : "");
                }}
              />
            </div>
            <div className="col-4 mt-4">
              <TextFiealdInput
                name="msme_no"
                label="MSME No"
                touched={touched}
                notRequire={values.msme ? false : true}
                readOnly={!values.msme ? true : false}
                errors={errors}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </div>
            <div className="col-4 mt-4">
              <AutoComplete
                pageOption={BusinessChannelOption}
                touched={touched}
                errors={errors}
                values={values}
                handleBlur={handleBlur}
                notRequire={values.partner_type === "C" ? false : true}
                name="business_chanel"
                label="Business Channel"
                vari={(e: any) => {
                  const data = props.business_channel_data.data.result.filter(
                    (a: any) => {
                      if (a.name === e) {
                        return a;
                      }
                    }
                  );
                  setFieldValue("business_chanel", e ? e.value : null);
                  setbusinessChannelActiveData(data[0]);
                }}
              />
            </div>
            <div className="col-4 mt-4">
              <AutoComplete
                pageOption={BusinessChannelStateOption}
                touched={touched}
                errors={errors}
                values={values}
                handleBlur={handleBlur}
                notRequire={values.partner_type === "C" ? false : true}
                name="state"
                label="Dimension State"
                vari={(e: any) => {
                  // const data = {
                  //   bussinesschannelid: businessChannelActiveData
                  //     ? businessChannelActiveData.id
                  //     : null,
                  //   partnerMasterId: props.onboardingValues.id,
                  // };
                  // dispatch(generate_cost_center_from_api(data));
                  // console.log(e);
                  setBusinessChanelState(e ? e.id : "");
                  setFieldValue("state", e ? e.value : "");
                }}
              />
            </div>
            {cost_center_data.data.length != 0
              ? cost_center_data.data.result.map((a: any, index: number) => {
                  return (
                    <div key={index} className="col-4 mt-4">
                      <TextFiealdInput
                        defaultValues={a.value}
                        name={a.branch.toLowerCase() + "_location"}
                        readOnly={true}
                        label={a.branch + " Location"}
                        touched={touched}
                        errors={errors}
                        values={a.value}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />
                    </div>
                  );
                })
              : props.onboardingValues.costcentercode.length != 0
              ? props.onboardingValues.costcentercode.map(
                  (a: any, index: number) => {
                    return (
                      <div key={index} className="col-4 mt-4">
                        <TextFiealdInput
                          defaultValues={a.value}
                          name={a.branch.toLowerCase() + "_location"}
                          readOnly={true}
                          label={a.branch + " Location"}
                          touched={touched}
                          errors={errors}
                          values={a.value}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />
                      </div>
                    );
                  }
                )
              : ""}
          </div>
        </div>
      </div>
    </>
  );
};

// general
const General = (props: any) => {
  const {
    touched,
    errors,
    handleBlur,
    handleChange,
    values,
    branch_master_data,
    setFieldTouched,
    setFieldValue,
  } = props;
  const TypeOfBusinessOption = props.generalSettingList.data.status
    ? props.generalSettingList.data.result
        .filter((e: any) => {
          if (e.page === "type_of_business") {
            return e;
          }
        })
        .map((e: any) => {
          const data = { label: e.description, value: e.name };
          return data;
        })
    : [{ label: "No data", value: "no data" }];

  return (
    <>
      <div className="mt-3  udf ">
        <div className="udf-form">
          <div className="row">
            <div className="col-4 mt-4">
              <TextFiealdInput
                name="telephone_no"
                label="Telephone No"
                notRequire={true}
                touched={touched}
                errors={errors}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </div>
            <div className="input-field  mt-4 col-4 d-flex flex-column">
              <label>
                Mobile Phone No <span>*</span>
              </label>
              <TextField
                placeholder=""
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.mobile_phone_no}
                error={
                  touched.mobile_phone_no && errors.mobile_phone_no
                    ? true
                    : false
                }
                helperText={
                  touched.mobile_phone_no && errors.mobile_phone_no
                    ? errors.mobile_phone_no
                    : ""
                }
                name="mobile_phone_no"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Select
                        value={values.country_code}
                        onChange={(e) => {
                          setFieldValue("country_code", `${e.target.value}`);
                        }}
                        name="country_code"
                        // displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          borderRadius: "0px",
                          height: "50px",
                          width: "100%",
                        }}
                      >
                        {COUNTRIES.map((e: any) => {
                          return (
                            <MenuItem value={e.mobileCode}>
                              {e.mobileCode}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: "0px",
                    height: "50px",
                    padding: "0px",
                  },
                }}
              />
            </div>
            <div className="col-4 mt-4">
              <TextFiealdInput
                name="email_id"
                label="Email Id"
                touched={touched}
                errors={errors}
                values={values}
                handleChange={(e: any) => {
                  setFieldValue("email_id", e.target.value.toLowerCase());
                }}
                handleBlur={handleBlur}
              />
            </div>
            <div className="col-4 mt-4">
              <AutoComplete
                pageOption={TypeOfBusinessOption}
                touched={touched}
                errors={errors}
                values={values}
                // handleChange={handleChange}
                handleBlur={handleBlur}
                name="type_of_business"
                label="Type Of Business"
                vari={(e: any) => {
                  setFieldValue("type_of_business", e ? e.value : "");
                }}
              />
            </div>
            <div className="col-12 p-0">
              <div className="input-field col-12 px-3">
                <label>
                  Branch <span>*</span>
                </label>

                <div className="d-flex align-items-center set-default-address">
                  <div className=" d-flex align-items-center ">
                    <FormControl
                      error={touched.branch && errors.branch ? true : false}
                    >
                      <FormGroup
                        onChange={(e: any) => {
                          const index = values.branch.findIndex(
                            (object: any) => {
                              return object.name === e.target.name;
                            }
                          );

                          if (index >= 0) {
                            let items: any = [...values.branch];
                            const data: any = {
                              name: e.target.name,
                              value: e.target.checked,
                              id: Number(e.target.id),
                            };
                            items[index] = data;

                            setFieldValue("branch", items);
                          } else {
                            const result: any = [...values.branch];
                            result.push({
                              name: e.target.name,
                              value: e.target.checked,
                              id: Number(e.target.id),
                            });
                            setFieldValue("branch", result);
                          }
                          setFieldTouched("branch", true, false);
                        }}
                        row
                      >
                        {branch_master_data.data.length != 0
                          ? branch_master_data.data.result.map(
                              (a: any, index: number) => {
                                return (
                                  <div key={index}>
                                    <FormControlLabel
                                      label={
                                        <img
                                          src={
                                            configs.api + "/" + a.branch_logo
                                          }
                                          style={{
                                            maxHeight: "50px",
                                            maxWidth: "200px",
                                          }}
                                          alt="branch name"
                                        ></img>
                                      }
                                      sx={{
                                        fontSize: "0.8vw",
                                      }}
                                      id={"12"}
                                      onChange={(e: any) => {
                                        // const data = [...arrayBranch];
                                        // data[0] = e.target.name;
                                        // setArrayBranch(data);
                                      }}
                                      name={a.branch_name}
                                      className="ms-3"
                                      control={
                                        <Checkbox
                                          id={JSON.stringify(a.id)}
                                          checked={
                                            values.branch.filter((e: any) => {
                                              return (
                                                e.name === a.branch_name &&
                                                e.value
                                              );
                                            }).length != 0
                                              ? true
                                              : false
                                          }
                                        />
                                      }
                                    />
                                  </div>
                                );
                              }
                            )
                          : ""}
                      </FormGroup>
                      {touched.branch && errors.branch ? (
                        <FormHelperText>branch is required</FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// contact info
const ContactInfo = (props: any) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [editData, setEditData] = useState<{
    id: string;
    default: boolean;
    contact_person: string;
    title: string;
    phone: string;
    email: string;
    country_code: string;
    index: number;
  }>({
    id: "",
    default: true,
    contact_person: "",
    title: "",
    phone: "",
    email: "",
    country_code: "+91",
    index: 0,
  });
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const contactvalidationSchema = Yup.object().shape({
    contact_person: Yup.string().max(50).required("Contact Person Is Required"),
    title: Yup.string().max(50).required("Title Is Required"),
    phone: Yup.string()
      .min(8)
      .max(50)
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone Number  Is Required"),
    email: Yup.string()
      .required("Email  Is Required")
      .max(100)
      .email("Email is invalid"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: isEdit ? editData.id : "",
      default: isEdit ? editData.default : true,
      contact_person: isEdit ? editData.contact_person : "",
      title: isEdit ? editData.title : "",
      phone: isEdit ? editData.phone : "",
      email: isEdit ? editData.email : "",
      country_code: isEdit ? editData.country_code : "+91",
    },
    validationSchema: contactvalidationSchema,
    onSubmit: (values) => {
      submit(values);
      console.log("submit", values);
    },
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
  } = formik;
  // console.log(isEdit);
  // console.log("values", values);
  // console.log("all", props.values.contact_person);

  let titleCheck = props.values.contact_person
    .map((item: any) => item.title.toLowerCase()) // Convert titles to lowercase
    .includes(values.title.toLowerCase()); // Check if values.title exists in lowercase

  // console.log("titleCheck", titleCheck);

  // const submit = (e: any) => {
  //   const data = [...props.values.contact_person];
  //   console.log("data", data);
  //   if (isEdit) {
  //     const filterData = data.map((value: any) => {
  //       const a = { ...value };
  //       if (e.default) {
  //         if (a.default) {
  //           a.default = false;
  //           return a;
  //         } else {
  //           return a;
  //         }
  //       } else {
  //         return a;
  //       }
  //     });
  //     filterData[editData.index] = e;
  //     props.contact_person_details(filterData);
  //     setIsEdit(false);
  //   } else {
  //     const filterData = data.map((value: any) => {
  //       const a = { ...value };
  //       if (e.default) {
  //         if (a.default) {
  //           a.default = false;
  //           return a;
  //         } else {
  //           return a;
  //         }
  //       } else {
  //         return a;
  //       }
  //     });

  //     filterData.push(e);
  //     props.contact_person_details(filterData);
  //     setIsEdit(false);
  //   }

  //   resetForm();
  //   handleClose();
  // };

  const submit = (e: any) => {
    const data = [...props.values.contact_person];
    console.log("data", data);

    // Check if titleCheck is true, if so, prevent form submission
    if (titleCheck) {
      // Show toast notification to the user
      toast.error(
        "TitleName already exists. Please choose a different titlename."
      );
      return; // Stop further execution of the function
    }

    if (isEdit) {
      const filterData = data.map((value: any) => {
        const a = { ...value };
        if (e.default) {
          if (a.default) {
            a.default = false;
            return a;
          } else {
            return a;
          }
        } else {
          return a;
        }
      });
      filterData[editData.index] = e;
      props.contact_person_details(filterData);
      setIsEdit(false);
    } else {
      const filterData = data.map((value: any) => {
        const a = { ...value };
        if (e.default) {
          if (a.default) {
            a.default = false;
            return a;
          } else {
            return a;
          }
        } else {
          return a;
        }
      });

      filterData.push(e);
      props.contact_person_details(filterData);
      setIsEdit(false);
    }

    resetForm();
    handleClose();
  };

  const [age, setAge] = React.useState(91);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChanges = (event: any) => {
    setAge(event.target.value);
  };
  const handle = (e: string) => {
    // console.log(e);
  };

  // mainTable
  const mainTableData: any = [];
  // mainTable data
  props.values.contact_person.forEach((e: any, key: number) => {
    const data = {
      default: {
        value: e.default ? "Yes" : "",
      },
      Name: {
        value: e.contact_person,
      },
      Title: {
        value: e.title,
      },
      phone_number: {
        value: e.phone,
      },
      email_id: {
        value: e.email,
      },
      action: {
        value: (
          <div
            className="d-flex table-edit-icon"
            style={{
              width: "23px",
              textAlign: "center",
            }}
          >
            <div
              className=" edit"
              onClick={() => {
                setIsEdit(true);
                let data = {
                  ...e,
                };
                data.index = key;
                setEditData(data);
                handleClickOpen();
              }}
            >
              <FontAwesomeIcon icon={["fas", "pen-to-square"]} />
            </div>
            <div
              className=" add"
              onClick={() => {
                setIsEdit(false);
                handleClickOpen();
                resetForm();
              }}
            >
              <FontAwesomeIcon icon={["fas", "plus"]} />
            </div>
          </div>
        ),
      },
    };
    mainTableData.push(data);
  });
  return (
    <>
      {props.values.contact_person.length != 0 ? (
        <MainTable add={handle} mainTableData={mainTableData} />
      ) : (
        <div className="d-flex justify-content-end align-items-center">
          <div
            className="add-contact"
            onClick={() => {
              handleClickOpen();
            }}
          >
            Add Contact
          </div>
        </div>
      )}
      <Dialog
        PaperProps={{
          style: { borderRadius: 0 },
        }}
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="d-flex">
          <DialogTitle id="alert-dialog-title" className="font-weight-700">
            {"Add Contact"}{" "}
          </DialogTitle>
          <div className="ms-auto p-3">
            <CancelIcon
              onClick={() => {
                handleClose();
              }}
              sx={{
                color: "#D1D1D1",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
          <div className="d-flex flex-column">
            <div className="row address-types mb-3">
              <div className="d-flex">
                <div className="d-flex align-items-center set-default-address">
                  <Checkbox
                    name="default"
                    checked={values.default ? true : false}
                    onChange={(e: any) => {
                      setFieldValue("default", e.target.checked);
                    }}
                  />
                  <label>Set to Default</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="input-field px-3 col-6 d-flex flex-column">
                <label>
                  Name <span>*</span>
                </label>
                <TextField
                  placeholder="Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.contact_person}
                  error={
                    touched.contact_person && errors.contact_person
                      ? true
                      : false
                  }
                  helperText={
                    touched.contact_person && errors.contact_person
                      ? errors.contact_person
                      : ""
                  }
                  name="contact_person"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    borderRadius: "0px",
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div>
              <div className="input-field px-3 col-6 d-flex flex-column">
                <label>
                  Title <span>*</span>
                </label>
                <TextField
                  placeholder="Title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  error={touched.title && errors.title ? true : false}
                  helperText={touched.title && errors.title ? errors.title : ""}
                  name="title"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    borderRadius: "0px",
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div>
              <div className="input-field px-3 mt-3 col-6 d-flex flex-column">
                <label>
                  Phone No <span>*</span>
                </label>
                <TextField
                  placeholder=""
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      border: "2px solid #c4c4c4",
                      borderRadius: "0px",
                    },
                    // borderRadius: "0px",
                    fontWeight: "700",
                    fontFamily: "Roboto",
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  error={touched.phone && errors.phone ? true : false}
                  helperText={touched.phone && errors.phone ? errors.phone : ""}
                  name="phone"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Select
                          value={values.country_code}
                          onChange={(e) => {
                            setFieldValue("country_code", `${e.target.value}`);
                          }}
                          name="country_code"
                          // displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          sx={{
                            borderRadius: "0px",
                            height: "50px",
                            width: "100%",
                          }}
                        >
                          {COUNTRIES.map((e: any) => {
                            // console.log(e);
                            return (
                              <MenuItem value={e.mobileCode}>
                                {e.mobileCode}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "0px",
                      height: "50px",
                      padding: "0px",
                    },
                  }}
                />
              </div>
              <div className="input-field px-3 mt-3 col-6 d-flex flex-column">
                <label>
                  Email Id <span>*</span>
                </label>
                <TextField
                  type="email"
                  placeholder="Email"
                  onChange={(e: any) => {
                    setFieldValue("email", e.target.value.toLowerCase());
                  }}
                  onBlur={handleBlur}
                  value={values.email}
                  error={touched.email && errors.email ? true : false}
                  helperText={touched.email && errors.email ? errors.email : ""}
                  name="email"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    borderRadius: "0px",
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div>
              <div className="col-12 d-flex justify-content-center">
                <div className="add-more px-3 col-3 d-flex flex-column">
                  <div onClick={handleClose} className="cancel">
                    Cancel
                  </div>
                </div>
                <div className="add-more px-3 col-3 d-flex flex-column">
                  <div
                    className="add"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Save
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </DialogContentText> */}
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
};

const AddressDetail = (props: any) => {
  const employee_read_only =
    props.values.partner_type == "V" && props.values.group_name == "162"
      ? false
      : props.gst_filling_status;

  const [ship_address_same, setShip_address_same] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [stateKey, setStateKey] = useState<string>("");
  const [crtAddType, setCrtAddType] = useState<string>("");
  const [crtTitle, setCrtTitle] = useState<number>();
  const [isAddAddress, setIsAddAddress] = useState<boolean>(true);

  // Delete Address
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleteDialogData, setDeleteData] = useState<IDeleteDialog>({});
  const [editData, setEditData] = useState<{
    id: any;
    default: boolean;
    address_type: string;
    address_title: string;
    address_name3: string;
    address_name2: string;
    street_pobox: string;
    block: string;
    city: string;
    zip_code: string;
    country: string;
    state: string;
    gst_type: string;
    gstin: string;
    gst_certificate: any;
    index: number;
  }>({
    id: "",
    default: true,
    address_type: "B",
    address_title: "",
    address_name3: "",
    address_name2: "",
    street_pobox: "",
    block: "",
    city: "",
    zip_code: "",
    country: "",
    state: "",
    gst_type: "",
    gstin: "",
    gst_certificate: {
      name: "",
    },
    index: 0,
  });
  const [copyData, setCopyData] = useState<{
    id: any;
    default: boolean;
    address_type: string;
    address_title: string;
    address_name3: string;
    address_name2: string;
    street_pobox: string;
    block: string;
    city: string;
    zip_code: string;
    country: string;
    state: string;
    gst_type: string;
    gstin: string;
    gst_certificate: any;
    index: number;
  }>({
    id: ship_address_same ? props.values.address_details[0].id : "",
    default: ship_address_same ? props.values.address_details[0].default : true,
    address_type: ship_address_same ? "ship_from" : "",
    address_title: ship_address_same
      ? props.values.address_details[0].address_title
      : "",
    address_name3: ship_address_same
      ? props.values.address_details[0].address_name3
      : "",
    address_name2: ship_address_same
      ? props.values.address_details[0].address_name2
      : "",
    street_pobox: ship_address_same
      ? props.values.address_details[0].street_pobox
      : "",

    block: ship_address_same ? props.values.address_details[0].block : "",
    city: ship_address_same ? props.values.address_details[0].city : "",
    zip_code: ship_address_same ? props.values.address_details[0].zip_code : "",
    country: ship_address_same ? props.values.address_details[0].country : "",
    state: ship_address_same ? props.values.address_details[0].state : "",
    gst_type: ship_address_same ? props.values.address_details[0].gst_type : "",
    gstin: ship_address_same ? props.values.address_details[0].gstin : "",
    gst_certificate: ship_address_same
      ? props.values.address_details[0].gst_certificate
      : "",
    index: 0,
  });
  var gstinformat =
    /[0-9]{2}[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}/;
  const addressvalidationSchema = Yup.object().shape({
    address_title: Yup.string()
      .max(100, "maximum 100 character")
      .required("Address Title Is Required"),
    address_name3: Yup.string()
      .max(100, "maximum 100 character")
      .required("Address Name3 Is Required"),
    address_name2: Yup.string()
      .max(100, "maximum 100 character")
      .required("Address Name2  Is Required"),
    street_pobox: Yup.string().max(100, "maximum 100 character").notRequired(),
    block: Yup.string().max(100, "maximum 100 character").notRequired(),
    zip_code: Yup.string()
      .max(100, "maximum 100 character")
      .required("Zip Code  Is Required"),
    country: Yup.string().required("Country  Is Required"),
    state: Yup.string().required("State  Is Required"),
    city: Yup.string()
      .max(100, "maximum 100 character")
      .required("city  Is Required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    gst_type: employee_read_only
      ? Yup.string().required("Gst Type  Is Required")
      : Yup.string().notRequired(),
    gstin: employee_read_only
      ? Yup.mixed()
          .required("GSTIN  Is Required")
          .test("gstvalidate", "invalid gst number", (value: any) => {
            if (gstinformat.test(value)) {
              return true;
            } else {
              return false;
            }
          })
      : Yup.mixed()
          .test("gstvalidate", "invalid gst number", (value: any) => {
            if (value) {
              if (gstinformat.test(value)) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          })
          .notRequired(),
    gst_certificate: employee_read_only
      ? Yup.mixed()
          .required("required gst certificate")
          .test(
            "GST Certificate required",
            "Gst Certificate Required",
            (value) => {
              if (value != null) {
                if (isEdit || ship_address_same) {
                  return true;
                }
                if (typeof value != "object" && typeof value === "object") {
                  return true;
                }
                if (value.name) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return false;
              }
            }
          )
          .test(
            "GST Certificate Required",
            "Unsupported Format - We only allow jpg,pdf,png,jpeg .",
            (value) => {
              if (value != null) {
                if (value.type) {
                  if (SUPPORTED_FORMATS.includes(value.type)) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              } else {
                return false;
              }
            }
          )
          .test("file size", "maximum file size 5mb", (value) => {
            if (value != null) {
              const fileSize = value.size / 1024 / 1024;
              if (value.type) {
                if (fileSize <= 5) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            } else {
              return false;
            }
          })
      : Yup.mixed()
          .test(
            "GST Certificate Required",
            "Unsupported Format - We only allow jpg,pdf,png,jpeg .",
            (value) => {
              if (value) {
                if (value.type) {
                  if (SUPPORTED_FORMATS.includes(value.type)) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              } else {
                return true;
              }
            }
          )
          .test("file size", "maximum file size 5mb", (value) => {
            if (value) {
              const fileSize = value.size / 1024 / 1024;
              if (value.type) {
                if (fileSize <= 5) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            } else {
              return true;
            }
          })
          .notRequired()
          .nullable(),
  });

  const pay_address_length = props.values.address_details.filter((a: any) => {
    return a.address_type === "B";
  }).length;

  const ship_add_length = props.values.address_details.filter((a: any) => {
    return a.address_type === "S";
  }).length;

  const pay_to_address_length: any =
    pay_address_length != 0 && isAddAddress && !isEdit
      ? pay_address_length + 1
      : isAddAddress && isEdit && crtAddType == "B"
      ? crtTitle
      : pay_address_length + 1;

  const ship_from_address_length: any =
    ship_add_length != 0 && isAddAddress && !isEdit
      ? ship_add_length + 1
      : isAddAddress && isEdit && crtAddType == "S"
      ? crtTitle
      : ship_add_length + 1;

  const address_title_default: any = isEdit
    ? editData.address_type
    : ship_address_same
    ? copyData.address_type
    : "B";

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: isEdit ? editData.id : ship_address_same ? copyData.id : "",
      default: isEdit
        ? editData.default
        : ship_address_same
        ? copyData.default
        : true,
      address_type: isEdit
        ? editData.address_type
        : ship_address_same
        ? copyData.address_type
        : "B",
      address_title: isEdit
        ? editData.address_title
        : ship_address_same && !setIsAddAddress
        ? copyData.address_title
        : address_title_default == "B"
        ? "Pay To Address" + pay_to_address_length
        : "Ship From Address" + ship_from_address_length,
      address_name3: isEdit
        ? editData.address_name3
        : ship_address_same
        ? copyData.address_name3
        : "",
      address_name2: isEdit
        ? editData.address_name2
        : ship_address_same
        ? copyData.address_name2
        : "",
      street_pobox: isEdit
        ? editData.street_pobox
        : ship_address_same
        ? copyData.street_pobox
        : "",
      block: isEdit ? editData.block : ship_address_same ? copyData.block : "",
      city: isEdit ? editData.city : ship_address_same ? copyData.city : "",
      zip_code: isEdit
        ? editData.zip_code
        : ship_address_same
        ? copyData.zip_code
        : "",
      country: isEdit
        ? editData.country
        : ship_address_same
        ? copyData.country
        : "",
      state: isEdit ? editData.state : ship_address_same ? copyData.state : "",
      gst_type: isEdit
        ? editData.gst_type
        : ship_address_same
        ? copyData.gst_type
        : "",
      gstin: isEdit ? editData.gstin : ship_address_same ? copyData.gstin : "",
      gst_certificate: isEdit
        ? editData.gst_certificate
        : ship_address_same
        ? copyData.gst_certificate
        : {
            name: "",
          },
    },
    validationSchema: addressvalidationSchema,
    onSubmit: (values) => {
      submit(values);
    },
  });

  const crt_add_type = isEdit
    ? editData.address_type
    : ship_address_same
    ? copyData.address_type
    : "B";
  useEffect(() => {
    if (crt_add_type) {
      setCrtAddType(crt_add_type);
    }
  }, [crt_add_type]);
  // setCrtAddType(crt_add_type);
  // console.log("AddressType>>>>>", crt_add_type);
  const addressTit = isEdit
    ? editData.address_title
    : ship_address_same && !setIsAddAddress
    ? copyData.address_title
    : address_title_default == "B"
    ? "Pay To Address" + pay_to_address_length
    : "Ship From Address" + ship_from_address_length;

  useEffect(() => {
    if (addressTit) {
      const match = addressTit.match(/\d+/);
      if (match) {
        const addTitNum = match[0];
        setCrtTitle(parseInt(addTitNum));
      } else {
        setCrtTitle(0); // or any default value you prefer
      }
    }
  }, [addressTit]);
  // console.log("AddressTitle>>>>>", addressTit);
  // console.log("IsEdit>>>>>", isEdit);

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
  } = formik;
  // console.log(copyData);
  const [age, setAge] = React.useState(91);
  const [open, setOpen] = React.useState(false);
  const GSTFileRep = createRef<any>();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const gst_certificate: any =
    errors.gst_certificate != undefined ? errors.gst_certificate : "";
  const handleClose = () => {
    setOpen(false);
    setShip_address_same(false);
  };

  // Handle Delete Address
  const handleDeleteAddress = (id: number) => {
    const addressData = [...props.values.address_details];
    const modifiedAddressData = addressData.map((data, i) => ({
      ...data,
      id: i,
    }));

    // console.log(id);
    // console.log(addressData);

    if (addressData.length === 1) {
      // dispatch(submit_Address_info_state([]));
      props.getAddress_details([]);
      setDeleteDialogOpen(false);
    } else {
      const filteredData = modifiedAddressData.filter((data) => data.id !== id);
      // dispatch(submit_Address_info_state(filteredData));
      props.getAddress_details(filteredData);
      setDeleteDialogOpen(false);
      // console.log(filteredData);
    }
  };
  // Submit
  const submit = (e: any) => {
    const data = [...props.values.address_details];
    if (isEdit) {
      const filterData = data.map((value: any) => {
        const a = { ...value };
        if (e.default) {
          if (a.default && a.address_type === e.address_type) {
            a.default = false;
            return a;
          } else {
            return a;
          }
        } else {
          return a;
        }
      });
      filterData[editData.index] = e;
      props.getAddress_details(filterData);
      // dispatch(submit_Address_info_state(filterData));
      setIsEdit(false);
    } else {
      const filterData = data.map((value: any) => {
        const a = { ...value };
        if (e.default) {
          if (a.default && a.address_type === e.address_type) {
            a.default = false;
            return a;
          } else {
            return a;
          }
        } else {
          return a;
        }
      });

      filterData.push(e);
      props.getAddress_details(filterData);
      setIsEdit(false);
    }

    // ;
    resetForm();
    handleClose();
    setShip_address_same(false);
  };
  //actions

  // mainTable data
  const mainTableData: any = [];
  props.values.address_details.forEach((e: any, key: number) => {
    const data = {
      default: {
        value: e.default ? "Yes" : "",
      },
      address_type: {
        value: e.address_type == "B" ? "Pay To " : "Ship From ",
      },
      address_title: {
        value: e.address_title ? e.address_title : "",
      },

      city: {
        value: e.city,
      },
      zip_code: {
        value: e.zip_code,
      },

      GSTIN: {
        value: e.gstin,
      },

      action: {
        value: (
          <div
            className="d-flex table-edit-icon"
            style={{
              width: "92px",
              textAlign: "center",
            }}
          >
            <div
              className=" edit"
              onClick={() => {
                setIsEdit(true);
                let data = {
                  ...e,
                };
                data.index = key;
                setEditData(data);
                handleClickOpen();
              }}
            >
              <FontAwesomeIcon title="Edit" icon={["fas", "pen-to-square"]} />
            </div>
            <div
              className=" edit"
              onClick={() => {
                let data = {
                  ...e,
                };
                data.index = key;
                setShip_address_same(true);
                setCopyData(data);
                setIsEdit(false);
                handleClickOpen();
              }}
            >
              <FontAwesomeIcon title="Copy" icon={["fas", "copy"]} />
            </div>
            <div
              className=" add"
              onClick={() => {
                setIsEdit(false);
                handleClickOpen();
              }}
            >
              <FontAwesomeIcon title="Add" icon={["fas", "plus"]} />
            </div>
            <Button
              className=" table_action_btn"
              disabled={e.default}
              onClick={() => {
                let data = {
                  ...e,
                };

                // console.log(data);
                setDeleteDialogOpen(true);
                setDeleteData({ id: key, name: data?.address_title });
              }}
            >
              <FontAwesomeIcon
                title="Delete"
                style={{ color: e.default ? "#808080b0" : "black" }}
                icon={["fas", "trash"]}
              />
            </Button>
          </div>
        ),
      },
    };
    mainTableData.push(data);
  });
  const country_option =
    props.generalSettingList.data.length != 0
      ? props.generalSettingList.data.status
        ? props.generalSettingList.data.result
            .filter((e: any) => {
              if (e.page === "country") {
                return e;
              }
            })
            .sort((a: any, b: any) => {
              return a.description > b.description ? 1 : -1;
            })
            .map((e: any) => {
              const data = { label: e.description, value: e.name };
              return data;
            })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];

  const state_option =
    props.generalSettingList.data.length != 0
      ? props.generalSettingList.data.status
        ? props.generalSettingList.data.result
            .filter((e: any) => {
              if (e.page === "state") {
                // console.log(stateKey);
                // console.log(e);
                if (e.key === stateKey) {
                  return e;
                }
              }
            })
            .sort((a: any, b: any) => {
              return a.description > b.description ? 1 : -1;
            })
            .map((e: any) => {
              const data = { label: e.description, value: e.name };
              return data;
            })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];
  //  gst type options
  const GstTypeOption = props.generalSettingList.data.status
    ? props.generalSettingList.data.result
        .filter((e: any) => {
          if (e.page === "gst_type") {
            return e;
          }
        })
        .map((e: any) => {
          const data = { label: e.description, value: e.name };
          return data;
        })
    : [{ label: "No data", value: "no data" }];

  return (
    <>
      {props.values.address_details.length != 0 ? (
        <MainTable mainTableData={mainTableData} />
      ) : (
        <div className="d-flex justify-content-end align-items-center">
          <div
            className="add-contact"
            onClick={() => {
              handleClickOpen();
            }}
          >
            Add Address
          </div>
        </div>
      )}
      <Dialog
        PaperProps={{
          style: { borderRadius: 0 },
        }}
        open={open}
        onClose={handleClose}
        maxWidth={"xl"}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="d-flex">
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              fontWeight: "700",
            }}
          >
            {isEdit ? "Edit Address" : "Add Address"}
          </DialogTitle>
          <div className="ms-auto p-3">
            <CancelIcon
              onClick={() => {
                handleClose();
                setIsAddAddress(true);
              }}
              sx={{
                color: "#D1D1D1",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
          <div className="d-flex flex-column">
            <div className="row address-types">
              <div className="d-flex">
                <div className="d-flex align-items-center set-default-address">
                  <Checkbox
                    name="default"
                    checked={values.default ? true : false}
                    onChange={(e: any) => {
                      setFieldValue("default", e.target.checked);
                    }}
                  />
                  <label>Set to Default</label>
                </div>
                {/* {!isEdit ? (
                  <div className="d-flex align-items-center set-default-address">
                    <Checkbox
                      name="default"
                      checked={ship_address_same}
                      onChange={(e: any) => {
                        if (props.values.address_details.length != 0) {
                          setCopyData(props.values.address_details[0]);
                          setShip_address_same(!ship_address_same);
                        }
                      }}
                    />
                    <label>copy address</label>
                  </div>
                ) : (
                  ""
                )} */}
              </div>
              <div className="col-12 align-items-center mt-1 ps-2 address-types d-flex">
                <div className="col-4 d-flex align-items-center mt-4">
                  <label>Address Type </label>
                  <FormGroup
                    row
                    onChange={(e: any) => {
                      if (e.target.name == "B") {
                        setFieldValue(
                          "address_title",
                          "Pay To Address" + pay_to_address_length
                        );
                      }
                      if (e.target.name == "S") {
                        setFieldValue(
                          "address_title",
                          "Ship From Address" + ship_from_address_length
                        );
                      }
                      setFieldValue("address_type", e.target.name);
                    }}
                  >
                    <FormControlLabel
                      label="Pay To"
                      sx={{
                        fontSize: "0.9vw",
                      }}
                      name="B"
                      className="ms-3"
                      control={
                        <Checkbox
                          checked={values.address_type === "B" ? true : false}
                        />
                      }
                    />

                    <FormControlLabel
                      label="Ship Form"
                      sx={{
                        fontSize: "0.8vw",
                      }}
                      name="S"
                      className="ms-3"
                      control={
                        <Checkbox
                          checked={values.address_type === "S" ? true : false}
                        />
                      }
                    />
                  </FormGroup>
                </div>
                {/* <div className="input-field px-3 col-4 d-flex flex-column">
                  <label>
                    Address Id <span>*</span>
                  </label>
                  <TextField
                    placeholder="Address Id"
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": {
                        top: 0,
                        border: "2px solid #c4c4c4",
                        borderRadius: "0px",
                      },
                      // borderRadius: "0px",
                      fontWeight: "700",
                      fontFamily: "Roboto",
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address_id}
                    error={
                      touched.address_id && errors.address_id ? true : false
                    }
                    helperText={
                      touched.address_id && errors.address_id
                        ? errors.address_id
                        : ""
                    }
                    name="address_id"
                    inputProps={{
                      style: {
                        height: "20px",
                      },
                    }}
                  />
                </div> */}
              </div>
              <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                <label>
                  Address Title <span>*</span>
                </label>
                <TextField
                  placeholder="Address Title"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      border: "2px solid #c4c4c4",
                      borderRadius: "0px",
                    },
                    // borderRadius: "0px",
                    fontWeight: "700",
                    fontFamily: "Roboto",
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address_title}
                  error={
                    touched.address_title && errors.address_title ? true : false
                  }
                  helperText={
                    touched.address_title && errors.address_title
                      ? errors.address_title
                      : ""
                  }
                  name="address_title"
                  InputProps={{
                    readOnly: true,
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div>
              <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                <label>
                  Address Name2 <span>*</span>
                </label>
                <TextField
                  placeholder="Address Name2"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    borderRadius: "0px",
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address_name2}
                  error={
                    touched.address_name2 && errors.address_name2 ? true : false
                  }
                  helperText={
                    touched.address_name2 && errors.address_name2
                      ? errors.address_name2
                      : ""
                  }
                  name="address_name2"
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div>
              <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                <label>
                  Address Name3 <span>*</span>
                </label>
                <TextField
                  placeholder="Address Name3"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    borderRadius: "0px",
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address_name3}
                  error={
                    touched.address_name3 && errors.address_name3 ? true : false
                  }
                  helperText={
                    touched.address_name3 && errors.address_name3
                      ? errors.address_name3
                      : ""
                  }
                  name="address_name3"
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div>
              <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                <label>Street / PO Box</label>
                <TextField
                  placeholder="Street"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    borderRadius: "0px",
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.street_pobox}
                  error={
                    touched.street_pobox && errors.street_pobox ? true : false
                  }
                  helperText={
                    touched.street_pobox && errors.street_pobox
                      ? errors.street_pobox
                      : ""
                  }
                  name="street_pobox"
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div>
              <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                <label>Block</label>
                <TextField
                  placeholder="Block"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    borderRadius: "0px",
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.block}
                  error={touched.block && errors.block ? true : false}
                  helperText={touched.block && errors.block ? errors.block : ""}
                  name="block"
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div>
              <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                <label>
                  City <span>*</span>
                </label>

                <TextField
                  placeholder="City"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      border: "2px solid #c4c4c4",
                      borderRadius: "0px",
                    },
                    // borderRadius: "0px",
                    fontWeight: "700",
                    fontFamily: "Roboto",
                  }}
                  onChange={(e: any) => {
                    if (e.target.value) {
                      setFieldValue(
                        "city",
                        e.target.value[0].toUpperCase() +
                          e.target.value.slice(1).toLowerCase()
                      );
                    } else {
                      setFieldValue("city", e.target.value);
                    }
                  }}
                  onBlur={handleBlur}
                  value={values.city}
                  error={touched.city && errors.city ? true : false}
                  helperText={touched.city && errors.city ? errors.city : ""}
                  name="city"
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div>
              <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                <label>
                  Zip Code <span>*</span>
                </label>
                <TextField
                  placeholder="Zip Code"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    borderRadius: "0px",
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.zip_code}
                  error={touched.zip_code && errors.zip_code ? true : false}
                  helperText={
                    touched.zip_code && errors.zip_code ? errors.zip_code : ""
                  }
                  name="zip_code"
                />
              </div>

              <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                <label>
                  Country <span>*</span>
                </label>
                <Autocomplete
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      border: "2px solid #c4c4c4",
                      borderRadius: "0px",
                    },
                    // borderRadius: "0px",
                    fontWeight: "700",
                    fontFamily: "Roboto",
                    height: "50px",
                    borderRadius: "0px",
                  }}
                  value={
                    country_option.filter((e: any) => {
                      return e.value === values.country;
                    }).length != 0
                      ? country_option.filter((e: any) => {
                          return e.value === values.country;
                        })[0].label
                      : values.country
                  }
                  renderOption={(
                    props: React.HTMLAttributes<HTMLLIElement>,
                    option: any
                  ) => {
                    return (
                      <li {...props} key={option.value}>
                        {option.label}
                      </li>
                    );
                  }}
                  options={country_option}
                  onChange={(e, value: any) => {
                    const a = value ? value.value : "";
                    // vari(a);
                    setStateKey(a);
                    setFieldValue("country", a);
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        textTransform: "capitalize",
                      }}
                      onBlur={handleBlur}
                      error={touched.country && errors.country ? true : false}
                      helperText={
                        touched.country && errors.country ? errors.country : ""
                      }
                      name="country"
                      placeholder="Select Type"
                      {...params}
                    />
                  )}
                />
              </div>

              <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                <label>
                  State <span>*</span>
                </label>
                <Autocomplete
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      border: "2px solid #c4c4c4",
                      borderRadius: "0px",
                    },
                    // borderRadius: "0px",
                    fontWeight: "700",
                    fontFamily: "Roboto",
                    height: "50px",
                    borderRadius: "0px",
                  }}
                  renderOption={(
                    props: React.HTMLAttributes<HTMLLIElement>,
                    option: any
                  ) => {
                    return (
                      <li {...props} key={option.value}>
                        {option.label}
                      </li>
                    );
                  }}
                  value={
                    state_option.filter((e: any) => {
                      return e.value === values.state;
                    }).length != 0
                      ? state_option.filter((e: any) => {
                          return e.value === values.state;
                        })[0].label
                      : values.state
                  }
                  options={state_option}
                  onChange={(e, value: any) => {
                    const a = value ? value.value : "";
                    // vari(a);

                    setFieldValue("state", a);
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        textTransform: "capitalize",
                      }}
                      onBlur={handleBlur}
                      error={touched.state && errors.state ? true : false}
                      helperText={
                        touched.state && errors.state ? errors.state : ""
                      }
                      name="state"
                      placeholder="Select Type"
                      {...params}
                    />
                  )}
                />
              </div>
              <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                <label>
                  GST Type {employee_read_only ? <span>*</span> : ""}
                </label>
                <Autocomplete
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    height: "50px",
                    borderRadius: "0px",
                  }}
                  value={
                    GstTypeOption.filter((e: any) => {
                      return e.value === values.gst_type;
                    }).length != 0
                      ? GstTypeOption.filter((e: any) => {
                          return e.value === values.gst_type;
                        })[0].label
                      : values.gst_type
                  }
                  renderOption={(
                    props: React.HTMLAttributes<HTMLLIElement>,
                    option: any
                  ) => {
                    return (
                      <li {...props} key={option.value}>
                        {option.label}
                      </li>
                    );
                  }}
                  readOnly={employee_read_only ? false : true}
                  options={GstTypeOption}
                  onChange={(e, value: any) => {
                    const a = value ? value.value : "";
                    // vari(a);

                    setFieldValue("gst_type", a);
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        textTransform: "capitalize",
                      }}
                      onBlur={handleBlur}
                      error={touched.gst_type && errors.gst_type ? true : false}
                      helperText={
                        touched.gst_type && errors.gst_type
                          ? errors.gst_type
                          : ""
                      }
                      name="gst_type"
                      placeholder="Select Type"
                      {...params}
                    />
                  )}
                />
              </div>
              <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                <label>GSTIN {employee_read_only ? <span>*</span> : ""}</label>
                <TextField
                  placeholder="GSTIN"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    borderRadius: "0px",
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                      textTransform: "uppercase",
                    },
                    readOnly: employee_read_only ? false : true,
                  }}
                  onChange={(e: any) => {
                    setFieldValue("gstin", e.target.value.toUpperCase());
                  }}
                  onBlur={handleBlur}
                  value={values.gstin}
                  error={touched.gstin && errors.gstin ? true : false}
                  helperText={touched.gstin && errors.gstin ? errors.gstin : ""}
                  name="gstin"
                />
              </div>
              <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                <label>
                  GST Certificate {employee_read_only ? <span>*</span> : ""}
                </label>
                <div
                  className={
                    errors.gst_certificate && touched.gst_certificate
                      ? "gst-certificate-require"
                      : "gst-certificate"
                  }
                  onClick={() => {
                    if (employee_read_only) {
                      GSTFileRep.current.click();
                    }
                  }}
                >
                  <FontAwesomeIcon
                    className="ms-50"
                    icon={["fas", "paperclip"]}
                    style={{
                      fontSize: "1vw",
                      marginLeft: "10px",
                      marginRight: "10px",
                      color: "#908f8f",
                    }}
                  />
                  <label>
                    {values.gst_certificate
                      ? values.gst_certificate.name === ""
                        ? "Upload GST Certificate"
                        : values.gst_certificate.name
                      : "Upload GST Certificate"}
                  </label>
                </div>
                <span
                  className={
                    errors.gst_certificate && touched.gst_certificate
                      ? "text-danger"
                      : ""
                  }
                >
                  {errors.gst_certificate && touched.gst_certificate ? (
                    gst_certificate
                  ) : values.gst_certificate ? (
                    values.gst_certificate.name !== "" ? (
                      <span
                        style={{
                          color: "#09b909",
                          fontSize: "10px",
                        }}
                      >
                        File attached
                      </span>
                    ) : (
                      "Supported file(.pdf,.jpg,.png) && maximum file size 5mb"
                    )
                  ) : (
                    "Supported file(.pdf,.jpg,.png) && maximum file size 5mb"
                  )}
                </span>
                <TextField
                  inputRef={GSTFileRep}
                  type="file"
                  name="gst_certificate"
                  onChange={(e: any) => {
                    setFieldValue("gst_certificate", e.target.files[0]);
                    setFieldTouched("gst_certificate", true, false);
                  }}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    borderRadius: "0px",
                    display: "none",
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div>

              {/* Pancard Certificate Field */}
              {/* <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                <label>
                  PANCARD Certificate {employee_read_only ? <span>*</span> : ""}
                </label>
                <div
                  className={
                    errors.gst_certificate && touched.gst_certificate
                      ? "pancard_certificate-require"
                      : "pancard_certificate"
                  }
                  onClick={() => {
                    if (employee_read_only) {
                      GSTFileRep.current.click();
                    }
                  }}
                >
                  <FontAwesomeIcon
                    className="ms-50"
                    icon={["fas", "paperclip"]}
                    style={{
                      fontSize: "1vw",
                      marginLeft: "10px",
                      marginRight: "10px",
                      color: "#908f8f",
                    }}
                  />
                  <label>
                    {values.gst_certificate
                      ? values.gst_certificate.name === ""
                        ? "Upload GST Certificate"
                        : values.gst_certificate.name
                      : "Upload GST Certificate"}
                  </label>
                </div>
                <span
                  className={
                    errors.gst_certificate && touched.gst_certificate
                      ? "text-danger"
                      : ""
                  }
                >
                  {errors.gst_certificate && touched.gst_certificate ? (
                    gst_certificate
                  ) : values.gst_certificate ? (
                    values.gst_certificate.name !== "" ? (
                      <span
                        style={{
                          color: "#09b909",
                          fontSize: "10px",
                        }}
                      >
                        File attached
                      </span>
                    ) : (
                      "Supported file(.pdf,.jpg,.png) && maximum file size 5mb"
                    )
                  ) : (
                    "Supported file(.pdf,.jpg,.png) && maximum file size 5mb"
                  )}
                </span>
                <TextField
                  inputRef={GSTFileRep}
                  type="file"
                  name="gst_certificate"
                  onChange={(e: any) => {
                    setFieldValue("gst_certificate", e.target.files[0]);
                    setFieldTouched("gst_certificate", true, false);
                  }}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    borderRadius: "0px",
                    display: "none",
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div> */}

              <div className="col-12 mt-4 d-flex justify-content-center">
                <div className="add-more px-3 col-3 d-flex flex-column">
                  <div onClick={handleClose} className="cancel">
                    Cancel
                  </div>
                </div>
                <div className="add-more px-3 col-3 d-flex flex-column">
                  <div
                    className="add"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Save
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </DialogContentText> */}
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
      <DeleteDialog
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        deleteFunction={() => handleDeleteAddress(deleteDialogData.id!)}
        deleteableName={deleteDialogData.name!}
      />
    </>
  );
};
// payment terms

const PaymentTerms = (props: any) => {
  const {
    touched,
    errors,
    handleBlur,
    handleChange,
    values,
    setFieldTouched,
    setFieldValue,
  } = props;
  // console.log("Values From Props", values);

  const dispatch = useDispatch();
  const ifsc_code_option = useSelector(
    (state: any) => state.BankIFSCCodeListApi
  );
  //  type of business options
  const price_list = props.generalSettingList.data.status
    ? props.generalSettingList.data.result
        .filter((e: any) => {
          if (e.page === "Pricelist") {
            return e;
          }
        })
        .map((e: any) => {
          const data = { key: e.key, label: e.description, value: e.name };
          return data;
        })
    : [{ label: "No data", value: "no data" }];
  //  gst type options
  const payment_terms = props.generalSettingList.data.status
    ? props.generalSettingList.data.result
        .filter((e: any) => {
          if (e.page === "Payment Terms") {
            return e;
          }
        })
        .map((e: any) => {
          const data = { key: e.key, label: e.description, value: e.name };
          return data;
        })
    : [{ label: "No data", value: "no data" }];
  const country_option =
    props.generalSettingList.data.length != 0
      ? props.generalSettingList.data.status
        ? props.generalSettingList.data.result
            .filter((e: any) => {
              if (e.page === "country") {
                return e;
              }
            })
            .map((e: any) => {
              const data = { label: e.description, value: e.name };
              return data;
            })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];
  const bank_names =
    props.bank_master_data.data.length != 0
      ? props.bank_master_data.data.status
        ? props.bank_master_data.data.result.map((e: any) => {
            const data = {
              code: e.BankCode,
              label: e.BankName,
              value: e.BankName,
            };
            return data;
          })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];
  const bank_ifsc_codes =
    ifsc_code_option.data.length != 0
      ? ifsc_code_option.data.status
        ? ifsc_code_option.data.result.map((e: any) => {
            const data = { label: e.IFC, value: e.IFC, branch: e.BRANCH };
            return data;
          })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];
  // console.log("IFSC_CODE_OPTIONS-aacounts-onboarding", ifsc_code_option);
  return (
    <>
      <div className="mt-3  udf ">
        <div className="udf-form">
          <div className="row">
            <div className="col-4 mt-4">
              <AutoComplete
                pageOption={payment_terms}
                name="payment_terms"
                touched={touched}
                errors={errors}
                values={values}
                // readOnly={true}
                handleBlur={handleBlur}
                label="Payment Terms"
                vari={(e: any) => {
                  // console.log(e);
                  props.setpayment_term_code(e ? e.key : "");
                  setFieldValue("payment_terms", e ? e.value : "");
                }}
              />
            </div>
            <div className="col-4 mt-4">
              <AutoComplete
                pageOption={price_list}
                name="price_list"
                touched={touched}
                errors={errors}
                values={values}
                handleBlur={handleBlur}
                label="Price List"
                vari={(e: any) => {
                  // console.log(e);
                  props.setPriceList(e ? e.key : "");
                  setFieldValue("price_list", e ? e.value : "");
                }}
              />
            </div>
            <div className="col-4 mt-4">
              <TextFiealdInput
                name="credit_limit"
                label="Credit Limit"
                alignend={true}
                notRequire={true}
                touched={touched}
                errors={errors}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </div>

            <div className="col-4 mt-4">
              <TextFiealdInput
                name="commitment_limit"
                label="Commitment Limit"
                touched={touched}
                notRequire={values.partner_type === "C" ? true : false}
                alignend={true}
                errors={errors}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </div>
            <div className="col-4 mt-4">
              <AutoComplete
                pageOption={country_option}
                name="bank_country"
                touched={touched}
                errors={errors}
                values={values}
                handleBlur={handleBlur}
                label="Bank Country/Region"
                vari={(e: any) => {
                  setFieldValue("bank_country", e ? e.value : "");
                }}
              />
            </div>
            <div className="col-4"></div>
            <div className="col-4 mt-4 input-field px-3 col-4 d-flex flex-column ">
              <label className="px-3">
                Bank Name <span>*</span>
              </label>
              <Autocomplete
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  height: "50px",
                  borderRadius: "0px",
                }}
                // readOnly={values.bank_name ? true : false}
                className="px-3 "
                value={values.bank_name}
                options={bank_names}
                onChange={(e, value: any) => {
                  const a = value ? value.value : "";
                  // console.log("Accounts_Onboarding_bank_code", value);
                  dispatch(clear_data_bank_Ifsc_code());
                  dispatch(
                    list_bank_Ifsc_code({
                      bank: a,
                    })
                  );
                  // vari(a);
                  setFieldValue("IFSC_code", "");
                  setFieldValue("payment_branch", "");

                  setFieldValue("bank_name", a);
                  setFieldValue("bank_code", value ? value.code : "");
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      textTransform: "capitalize",
                    }}
                    onBlur={handleBlur}
                    error={touched.bank_name && errors.bank_name ? true : false}
                    helperText={
                      touched.bank_name && errors.bank_name
                        ? errors.bank_name
                        : ""
                    }
                    name="bank_name"
                    placeholder="Select Type"
                    {...params}
                  />
                )}
              />
            </div>

            <div className="input-field px-3 col-4 d-flex flex-column pt-4">
              <label className="px-3">
                BIC/IFSC/SWIFT Code <span>*</span>
              </label>
              <Autocomplete
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  height: "50px",
                  borderRadius: "0px",
                }}
                // readOnly={values.IFSC_code ? true : false}
                className="px-3"
                value={values.IFSC_code}
                options={bank_ifsc_codes}
                onChange={(e, value: any) => {
                  const a = value ? value.value : "";

                  // vari(a);
                  // console.log("IFSC_CODE_accounts-onboarding", a);

                  setFieldValue("payment_branch", value ? value.branch : "");

                  setFieldValue("IFSC_code", a);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      textTransform: "capitalize",
                    }}
                    onBlur={handleBlur}
                    error={touched.IFSC_code && errors.IFSC_code ? true : false}
                    helperText={
                      touched.IFSC_code && errors.IFSC_code
                        ? errors.IFSC_code
                        : ""
                    }
                    name="IFSC_code"
                    placeholder="Select Type"
                    {...params}
                  />
                )}
              />
            </div>

            <div className="col-4 mt-4">
              <TextFiealdInput
                name="account_no"
                label="Account No"
                touched={touched}
                errors={errors}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </div>
            <div className="col-4 mt-4">
              <TextFiealdInput
                name="bank_account_name"
                label="Bank Account Name"
                touched={touched}
                errors={errors}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </div>
            <div className="col-4 mt-4">
              <TextFiealdInput
                name="payment_branch"
                label="Branch"
                touched={touched}
                errors={errors}
                values={values}
                readOnly
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// accounts

const Accounts = (props: any) => {
  const {
    touched,
    errors,
    handleBlur,
    handleChange,
    values,
    setConsolidationCode,
    setFieldTouched,
    setFieldValue,
  } = props;

  // console.log(
  //   props.AsseseeType.length != 0
  //     ? props.AsseseeType
  //     : props.onboardingValues.accounts.length != 0
  //     ? props.onboardingValues.accounts[0].Assesse_type
  //     : props.AsseseeType
  // );

  // console.log(props.down_pay_account_data);
  const down_pay_account_code =
    props.down_pay_account_data.data.length != 0
      ? props.down_pay_account_data.data.data.length != 0
        ? props.down_pay_account_data.data.data.map((e: any) => {
            const data = { label: e.AcctName, value: e.AcctCode };
            return data;
          })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];
  const wtax_code_option =
    props.wtax_master_data.data.length != 0
      ? props.wtax_master_data.data.length != 0
        ? props.wtax_master_data.data.data
            .filter((e: any) => {
              if (
                (props.AsseseeType.length != 0
                  ? props.AsseseeType
                  : props.onboardingValues.accounts.length != 0
                  ? props.onboardingValues.accounts[0].Assesse_type
                  : props.AsseseeType) === e.Assessee
              ) {
                return e;
              }
            })
            .map((e: any) => {
              const data = { id: e.id, label: e.WTName, value: e.WTCode };

              return data;
            })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];
  const assessee_type_option =
    props.generalSettingList.data.length != 0
      ? props.generalSettingList.data.status
        ? props.generalSettingList.data.result
            .filter((e: any) => {
              if (e.page === "assesee_type") {
                return e;
              }
            })
            .map((e: any) => {
              const data = { key: e.key, label: e.description, value: e.name };
              return data;
            })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];
  const consalidationBusinessPartner =
    props.consalidationBusinessPartnerList.data.length != 0
      ? props.consalidationBusinessPartnerList.data.status
        ? props.consalidationBusinessPartnerList.data.result.map((e: any) => {
            const data = {
              id: e.id,
              label: e.company_name,
              value: e.id,
            };
            return data;
          })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];
  const Acounts_payable =
    props.onboardingValues.partner_type != "C"
      ? props.vendor_pay_data.data.length != 0
        ? props.vendor_pay_data.data.status
          ? props.vendor_pay_data.data.result.map((e: any) => {
              const data = { label: e.AcctName, value: e.AcctCode };
              return data;
            })
          : [{ label: "No data", value: null }]
        : [{ label: "No data", value: null }]
      : props.customer_receivable_data.data.length != 0
      ? props.customer_receivable_data.data.data.map((e: any) => {
          const data = { label: e.AcctName, value: e.AcctCode };
          return data;
        })
      : [{ label: "No data", value: null }];

  // console.log(Acounts_payable);
  return (
    <>
      <div className="mt-3  udf ">
        <div className="udf-form">
          <div className="row">
            <div className="col-4 mt-4">
              <AutoComplete
                pageOption={consalidationBusinessPartner}
                touched={touched}
                errors={errors}
                notRequire={true}
                values={values}
                handleBlur={handleBlur}
                name="consolidation_business_partner"
                label="Consolidation Business Partner"
                vari={(e: any) => {
                  setConsolidationCode(e ? e.id : "");
                  setFieldValue(
                    "consolidation_business_partner",
                    e ? e.value : ""
                  );
                }}
              />
            </div>
            <div className="col-4 mt-4">
              <AutoComplete
                pageOption={Acounts_payable}
                name="accounts_payable"
                label="Accounts Payable"
                touched={touched}
                errors={errors}
                values={values}
                handleBlur={handleBlur}
                vari={(e: any) => {
                  setFieldValue("accounts_payable", e ? e.value : "");
                }}
              />
            </div>

            <div className="col-4 mt-4">
              <AutoComplete
                pageOption={down_pay_account_code}
                name="down_payment_clearing_accounts"
                label="Down Payment Clearing Account"
                touched={touched}
                errors={errors}
                values={values}
                handleBlur={handleBlur}
                vari={(e: any) => {
                  setFieldValue(
                    "down_payment_clearing_accounts",
                    e ? e.value : ""
                  );
                }}
              />
            </div>
            <div className="col-4 mt-4">
              <AutoComplete
                pageOption={down_pay_account_code}
                name="down_payment_interim_accounts"
                label="Down Payment Interim Account"
                touched={touched}
                errors={errors}
                values={values}
                handleBlur={handleBlur}
                vari={(e: any) => {
                  setFieldValue(
                    "down_payment_interim_accounts",
                    e ? e.value : ""
                  );
                }}
              />
            </div>
            {/* <div className="col-4 mt-4">
              <TextFiealdInput
                name="down_payment_interim_accounts"
                label="Down Payment Interim Account"
                touched={touched}
                errors={errors}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </div> */}
            <div className="col-4 mt-4">
              <TextFiealdInput
                name="pan_number"
                handleChange={handleChange}
                label="PAN No"
                readOnly={
                  values.partner_type == "V" && values.group_name === "162"
                    ? true
                    : false
                }
                notRequire={
                  values.partner_type == "V" && values.group_name === "162"
                    ? true
                    : false
                }
                uppercase={true}
                touched={touched}
                errors={errors}
                values={values}
                handleBlur={handleBlur}
              />
            </div>
            <div className="col-4 mt-4 d-flex align-items-end">
              <FormControlLabel
                label={"Subject To Withholding Tax"}
                sx={{
                  fontSize: "0.8vw",
                  color: "",
                }}
                value={values.subject_holding}
                onChange={(e: any) => {
                  if (
                    values.partner_type != "V" ||
                    values.group_name != "162"
                  ) {
                    if (!e.target.checked) {
                      setFieldValue("wtax_code", []);
                      props.setAsseseeType("");
                      setFieldValue("Assesse_type", null);
                    }
                    setFieldValue("subject_holding", e.target.checked);
                  }
                }}
                className="ms-3"
                control={
                  <Checkbox
                    name="subject_holding"
                    checked={values.subject_holding ? true : false}
                  />
                }
              />
            </div>
            <div className="col-4 mt-4">
              <AutoComplete
                pageOption={assessee_type_option}
                name="Assesse_type"
                label="Assessee Type"
                touched={touched}
                notRequire={!values.subject_holding ? true : false}
                readOnly={!values.subject_holding ? true : false}
                errors={errors}
                values={values}
                handleBlur={handleBlur}
                vari={(e: any) => {
                  setFieldValue("wtax_code", []);
                  props.setAsseseeType(e ? e.value : "");
                  setFieldValue("Assesse_type", e ? e.value : "");
                }}
              />
            </div>
            <div className="col-8 mt-4">
              <AutoCompleteMultiple
                pageOption={wtax_code_option}
                name="wtax_code"
                label="WTAX Code"
                touched={touched}
                notRequire={!values.subject_holding ? true : false}
                readOnly={!values.subject_holding ? true : false}
                errors={errors}
                values={values}
                handleBlur={handleBlur}
                vari={(e: any) => {
                  // console.log(e);
                  setFieldValue("wtax_code", e);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Attachments

// const Attachments = (props: any) => {
//   const gstCertificateRep = createRef<any>();

//   const pageOption = [
//     { label: "Type 1", value: "Type 1" },
//     { label: "Type 2", value: "Type 2" },
//   ];
//   const api = configs.api + "/";
//   return (
//     <>
//       <div className="mt-3  udf ">
//         <div className="udf-form">
//           <div className="row">
//             <div className="col-4 input-field d-flex flex-column">
//               <label>GST Certificate</label>
//               <div className={"gst-certificate-view-page"}>
//                 {/* <FontAwesomeIcon
//                   className="ms-50"
//                   icon={["fas", "paperclip"]}
//                   style={{
//                     fontSize: "1vw",
//                     marginLeft: "10px",
//                     marginRight: "10px",
//                     color: "#908f8f",
//                   }}
//                 /> */}
//                 {/* <label className="text-dark text-start mx-2">
//                   GST Certificate.pdf
//                 </label> */}
//                 <a
//                   className="px-3"
//                   href={
//                     props.onboardingValues.contactAddress.length === 0
//                       ? ""
//                       : api +
//                         props.onboardingValues.contactAddress[0].gst_certificate
//                   }
//                   target="_blank"
//                 >
//                   GST Certificate.pdf
//                 </a>
//                 <div className="text-dark text-end ms-auto me-2">
//                   <label className="text-dark">1.4mb</label>
//                   <DownloadIcon
//                     className="mx-2 "
//                     sx={{
//                       color: "#46A4E3",
//                     }}
//                   />
//                 </div>
//               </div>
//               <span
//               // className={
//               //   errors.msme_certificate && touched.msme_certificate
//               //     ? "text-danger"
//               //     : ""
//               // }
//               >
//                 Supported file(.pdf,.jpg,.png)
//               </span>
//               {/* <TextField
//                 inputRef={gstCertificateRep}
//                 type="file"
//                 // value={values.msme_certificate}
//                 name="msme_certificate"
//                 onChange={(e: any) => {
//                   // setFieldValue("msme_certificate", e.target.files[0]);
//                   // setFieldTouched("msme_certificate", true, false);
//                 }}
//                 sx={{
//                   "& legend": { display: "none" },
//                   "& fieldset": { top: 0 },
//                   borderRadius: "0px",
//                   display: "none",
//                 }}
//                 inputProps={{
//                   style: {
//                     height: "20px",
//                   },
//                 }}
//               /> */}
//             </div>
//             <div className="col-4 input-field d-flex flex-column">
//               <label>Cancelled Cheque Leaf</label>
//               <div className={"gst-certificate-view-page"}>
//                 {/* <FontAwesomeIcon
//                   className="ms-50"
//                   icon={["fas", "paperclip"]}
//                   style={{
//                     fontSize: "1vw",
//                     marginLeft: "10px",
//                     marginRight: "10px",
//                     color: "#908f8f",
//                   }}
//                 /> */}
//                 {/* <label className="text-dark text-start mx-2">
//                   Cancelled Cheque Leaf.pdf
//                 </label> */}
//                 <a
//                   className="px-3"
//                   href={
//                     props.onboardingValues.bankDetail.length === 0
//                       ? ""
//                       : api +
//                         props.onboardingValues.bankDetail[0]
//                           .cancelled_cheque_url
//                   }
//                   target="_blank"
//                 >
//                   Cancelled Cheque Leaf.pdf
//                 </a>
//                 <div className="text-dark text-end ms-auto me-2">
//                   <label className="text-dark">1.4mb</label>
//                   <DownloadIcon
//                     className="mx-2 "
//                     sx={{
//                       color: "#46A4E3",
//                     }}
//                   />
//                 </div>
//               </div>
//               <span
//               // className={
//               //   errors.msme_certificate && touched.msme_certificate
//               //     ? "text-danger"
//               //     : ""
//               // }
//               >
//                 Supported file(.pdf,.jpg,.png)
//               </span>
//               {/* <TextField
//                 inputRef={gstCertificateRep}
//                 type="file"
//                 // value={values.msme_certificate}
//                 name="msme_certificate"
//                 onChange={(e: any) => {
//                   // setFieldValue("msme_certificate", e.target.files[0]);
//                   // setFieldTouched("msme_certificate", true, false);
//                 }}
//                 sx={{
//                   "& legend": { display: "none" },
//                   "& fieldset": { top: 0 },
//                   borderRadius: "0px",
//                   display: "none",
//                 }}
//                 inputProps={{
//                   style: {
//                     height: "20px",
//                   },
//                 }}
//               /> */}
//             </div>
//             <div className="col-4 input-field d-flex flex-column">
//               <label>MSME Certificate</label>
//               <div className={"gst-certificate-view-page"}>
//                 {/* <FontAwesomeIcon
//                   className="ms-50"
//                   icon={["fas", "paperclip"]}
//                   style={{
//                     fontSize: "1vw",
//                     marginLeft: "10px",
//                     marginRight: "10px",
//                     color: "#908f8f",
//                   }}
//                 /> */}
//                 {/* <label className="text-dark text-start mx-2">

//                 </label> */}
//                 <a
//                   className="px-3"
//                   href={
//                     api +
//                     (props.onboardingValues.businessPartner.length != 0
//                       ? props.onboardingValues.businessPartner[0]
//                           .msme_certificate_file
//                       : "")
//                   }
//                   target="_blank"
//                 >
//                   MSME Certificate.pdf
//                 </a>
//                 <div className="text-dark text-end ms-auto me-2">
//                   <label className="text-dark">1.4mb</label>
//                   <DownloadIcon
//                     className="mx-2 "
//                     sx={{
//                       color: "#46A4E3",
//                     }}
//                   />
//                 </div>
//               </div>
//               <span
//               // className={
//               //   errors.msme_certificate && touched.msme_certificate
//               //     ? "text-danger"
//               //     : ""
//               // }
//               >
//                 Supported file(.pdf,.jpg,.png)
//               </span>
//               {/* <TextField
//                 inputRef={gstCertificateRep}
//                 type="file"
//                 // value={values.msme_certificate}
//                 name="msme_certificate"
//                 onChange={(e: any) => {
//                   // setFieldValue("msme_certificate", e.target.files[0]);
//                   // setFieldTouched("msme_certificate", true, false);
//                 }}
//                 sx={{
//                   "& legend": { display: "none" },
//                   "& fieldset": { top: 0 },
//                   borderRadius: "0px",
//                   display: "none",
//                 }}
//                 inputProps={{
//                   style: {
//                     height: "20px",
//                   },
//                 }}
//               /> */}
//             </div>
//             <div className="col-4 input-field d-flex flex-column">
//               <label>PANCARD Certificate</label>
//               <div className={"gst-certificate-view-page"}>
//                 <a
//                   className="px-3"
//                   href={
//                     api +
//                     (props.onboardingValues.businessPartner.length != 0
//                       ? props.onboardingValues.businessPartner[0]
//                           .pancard_certificate_file
//                       : "")
//                   }
//                   target="_blank"
//                 >
//                   PANCARD Certificate.pdf
//                 </a>
//                 <div className="text-dark text-end ms-auto me-2">
//                   <label className="text-dark">1.4mb</label>
//                   <DownloadIcon
//                     className="mx-2 "
//                     sx={{
//                       color: "#46A4E3",
//                     }}
//                   />
//                 </div>
//               </div>
//               <span>Supported file(.pdf,.jpg,.png)</span>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

const Attachments = (props: any) => {
  const gstCertificateRep = createRef();

  const api = configs.api + "/";
  const handleDownload = async (url: string, fileName: string) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };

  const extractFileName = (url: string | undefined): string => {
    if (!url) return "default-filename";
    return url.split("/").pop() || "default-filename";
  };

  return (
    <>
      <div className="mt-3 udf">
        <div className="udf-form">
          <div className="row">
            {props.onboardingValues.contactAddress.length > 0 &&
              props.onboardingValues.contactAddress[0].gst_certificate && (
                <div
                  className="col-4 input-field d-flex flex-column"
                  key="gst-certificate"
                >
                  <label>GST Certificate</label>
                  <div className={"gst-certificate-view-page"}>
                    <a
                      className="px-3"
                      href={
                        api +
                        props.onboardingValues.contactAddress[0].gst_certificate
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      GST Certificate
                    </a>
                    <div className="text-dark text-end ms-auto me-2">
                      <label className="text-dark">1.4mb</label>
                      <DownloadIcon
                        className="mx-2"
                        sx={{ color: "#46A4E3" }}
                        onClick={() =>
                          handleDownload(
                            api +
                              props.onboardingValues.contactAddress[0]
                                ?.gst_certificate ?? "",
                            extractFileName(
                              props.onboardingValues.contactAddress[0]
                                ?.gst_certificate
                            )
                          )
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <span>Supported file(.pdf,.jpg,.png)</span>
                </div>
              )}

            {props.onboardingValues.bankDetail.length > 0 &&
              props.onboardingValues.bankDetail[0].cancelled_cheque_url && (
                <div
                  className="col-4 input-field d-flex flex-column"
                  key="cancelled-cheque"
                >
                  <label>Cancelled Cheque Leaf</label>
                  <div className={"gst-certificate-view-page"}>
                    <a
                      className="px-3"
                      href={
                        api +
                        props.onboardingValues.bankDetail[0]
                          .cancelled_cheque_url
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Cancelled Cheque Leaf
                    </a>
                    <div className="text-dark text-end ms-auto me-2">
                      <label className="text-dark">1.4mb</label>
                      <DownloadIcon
                        className="mx-2"
                        sx={{ color: "#46A4E3" }}
                        onClick={() =>
                          handleDownload(
                            api +
                              props.onboardingValues.bankDetail[0]
                                ?.cancelled_cheque_url ?? "",
                            extractFileName(
                              props.onboardingValues.bankDetail[0]
                                ?.cancelled_cheque_url
                            )
                          )
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <span>Supported file(.pdf,.jpg,.png)</span>
                </div>
              )}

            {props.onboardingValues.businessPartner.length > 0 &&
              props.onboardingValues.businessPartner[0]
                .msme_certificate_file && (
                <div
                  className="col-4 input-field d-flex flex-column"
                  key="msme-certificate"
                >
                  <label>MSME Certificate</label>
                  <div className={"gst-certificate-view-page"}>
                    <a
                      className="px-3"
                      href={
                        api +
                          props.onboardingValues.businessPartner[0]
                            ?.msme_certificate_file ?? ""
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MSME Certificate
                    </a>
                    <div className="text-dark text-end ms-auto me-2">
                      <label className="text-dark">1.4mb</label>
                      <DownloadIcon
                        className="mx-2"
                        sx={{ color: "#46A4E3" }}
                        onClick={() =>
                          handleDownload(
                            api +
                              props.onboardingValues.businessPartner[0]
                                ?.msme_certificate_file ?? "",
                            extractFileName(
                              props.onboardingValues.businessPartner[0]
                                ?.msme_certificate_file
                            )
                          )
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <span>Supported file(.pdf,.jpg,.png)</span>
                </div>
              )}

            {props.onboardingValues.businessPartner.length > 0 &&
              props.onboardingValues.businessPartner[0]
                .pancard_certificate_file && (
                <div
                  className="col-4 input-field d-flex flex-column"
                  key="pancard-certificate"
                >
                  <label>PANCARD Certificate</label>
                  <div className={"gst-certificate-view-page"}>
                    <a
                      className="px-3"
                      href={
                        api +
                          props.onboardingValues.businessPartner[0]
                            ?.pancard_certificate_file ?? ""
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      PANCARD Certificate
                    </a>
                    <div className="text-dark text-end ms-auto me-2">
                      <label className="text-dark">1.4mb</label>
                      <DownloadIcon
                        className="mx-2"
                        sx={{ color: "#46A4E3" }}
                        onClick={() =>
                          handleDownload(
                            api +
                              props.onboardingValues.businessPartner[0]
                                ?.pancard_certificate_file ?? "",
                            extractFileName(
                              props.onboardingValues.businessPartner[0]
                                ?.pancard_certificate_file
                            )
                          )
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <span>Supported file(.pdf,.jpg,.png)</span>
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

const RequestClarification = (props: any) => {
  const {
    CloseRequestClarification,
    requestForClarification,
    onboardingValues,
  } = props;
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    about: Yup.string().required("reson for clarification is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      about: "",
    },
    validationSchema: validationSchema,
    onSubmit: (a: any) => {
      submit(a);
    },
  });
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
  } = formik;
  const reuest_clarification_api = useSelector(
    (state: any) => state.RequestClarification
  );

  const submit = (a: any) => {
    const data = {
      id: onboardingValues.id,
      description: a.about,
      status: "Request For Clarification",
    };
    dispatch(request_clarification(data));
  };

  return (
    <>
      {/* management reject reason */}
      <Dialog
        PaperProps={{
          style: { borderRadius: 0, width: "700px" },
        }}
        open={requestForClarification}
        onClose={CloseRequestClarification}
        maxWidth={"lg"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="d-flex">
          <DialogTitle id="alert-dialog-title" className="font-weight-700 pb-0">
            Request for Clarification
          </DialogTitle>

          <div className="ms-auto p-3">
            <CancelIcon
              onClick={() => {
                CloseRequestClarification();
              }}
              sx={{
                color: "#D1D1D1",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <DialogContent className="pt-0">
          <div className="input-field pt-0">
            <label>Reson for Clarification</label>
            <TextField
              fullWidth
              id="reason-reject"
              className="reason-reject"
              multiline
              name="about"
              onChange={handleChange}
              value={values.about}
              onBlur={handleBlur}
              error={touched.about && errors.about ? true : false}
              helperText={
                touched.about && errors.about
                  ? (errors.about as ReactNode)
                  : undefined
              }
              sx={{
                height: "80px",
              }}
              inputProps={{
                style: {
                  height: "80px",
                },
              }}
            />
          </div>

          <div className="d-flex flex-column">
            <div></div>
            <div className="col-12 d-flex justify-content-center">
              <div
                className="add-more px-3 col-3 d-flex flex-column"
                style={{
                  width: "200px",
                }}
              >
                <div onClick={CloseRequestClarification} className="cancel">
                  Cancel
                </div>
              </div>
              <div
                className="add-more px-3 col-3 d-flex flex-column"
                style={{
                  width: "200px",
                }}
              >
                <div
                  className="add"
                  onClick={() => {
                    // ManagementRejectClose();
                    handleSubmit();
                  }}
                >
                  {reuest_clarification_api.isLoading ? "Please Wait" : "Send"}
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
