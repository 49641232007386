import { TextField } from "@mui/material";
import { StylesProvider } from "@material-ui/core/styles";
const TextFiealdInput = (props: any) => {
  const {
    style,
    onBlur,
    pageOption,
    handleChange,
    touched,
    onchange,
    setFieldValue,
    disable,
    uppercase,
    label,
    notRequire,
    readOnly,
    defaultValues,
    handchange,
    handleBlur,
    borderless,
    alignend,
    errors,
    values,

    name,
  } = props;

  return (
    <>
      <StylesProvider injectFirst>
        <div className="input-field px-3  d-flex flex-column">
          <label>
            {label} {notRequire || borderless ? "" : <span>*</span>}
          </label>
          <TextField
            type="text"
            placeholder={label}
            name={name}
            disabled={disable}
            defaultValue={defaultValues ? defaultValues : ""}
            sx={
              borderless
                ? {
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      border: "none",

                      borderRadius: "0px",
                    },

                    // borderRadius: "0px",
                    fontWeight: "700",
                    fontFamily: "Roboto",
                  }
                : {
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      // border: "2px solid #c4c4c4",

                      borderRadius: "0px",
                    },
                    // borderRadius: "0px",
                    fontWeight: "700",
                    fontFamily: "Roboto",
                  }
            }
            inputProps={{
              style: alignend
                ? uppercase
                  ? {
                      height: "20px",
                      textAlign: "end",
                      textTransform: "uppercase",
                    }
                  : {
                      height: "20px",
                      textAlign: "end",
                    }
                : uppercase
                ? {
                    height: "20px",
                    textTransform: "uppercase",
                  }
                : {
                    height: "20px",
                  },
              readOnly: readOnly ? true : false,
            }}
            onBlur={handleBlur}
            error={touched[name] && errors[name] ? true : false}
            helperText={touched[name] && errors[name] ? errors[name] : ""}
            onChange={handleChange}
            value={values[name]}
          />
        </div>
      </StylesProvider>
    </>
  );
};
export default TextFiealdInput;
