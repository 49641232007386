import "./DeleteDialog.css";
import { FunctionComponent, useEffect, useState } from "react";
import { DeleteDialogProps } from "./IDeleteDialog";
import Dialog from "../Dialog/Dialog";
import { Button } from "@mui/material";

const DeleteDialog: FunctionComponent<DeleteDialogProps> = (props) => {
  const { open, setOpen, deleteFunction, deleteableName } = props;
  const [responseDialog, setResponseDialog] = useState<boolean>(false);
  const handleClose = () => {
    setOpen(false);
    setResponseDialog(false);
  };

  return (
    <>
      <Dialog
        open={open}
        dialogClassName="delete_dialog"
        dialogTitleClassName="delete_dialog_title"
        Dialog_Title={
          <>
            Delete
            {/* <IconButtonField onClick={() => setOpen(false)}>
              <SvgIcon component={Cancel} inheritViewBox />
            </IconButtonField> */}
          </>
        }
        dialogContentClassName="delete_dialog_content"
        Dialog_Content={
          <>
            Are you sure you want to delete <b>{deleteableName}</b>?
          </>
        }
        dialogActionClassName="delete_dialog_action"
        Dialog_Actions={
          <>
            <Button
              className="delete_cancel_btn"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button className="delete_conf_btn" onClick={deleteFunction}>
              Delete
            </Button>
          </>
        }
      />
    </>
  );
};

export default DeleteDialog;
